import React, { useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { fetchOrkuData } from "../../../felles/APICalls_NO01";
import { TradingPartner } from "../../../models/NO01Orku";
import CSharpUtils from "../../../felles/CSharpUtils";
import FilterTextField from "./FilterTextField";

export interface OrkuData {
  customerNumber: number;
  companyId: string;
  customerName: string;
  ytdSales: number;
}

const Orku: React.FC = () => {
  const [orkuData, setOrkuData] = useState<TradingPartner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1); // Start from page 1 (Pagination component starts at 1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(1000); // Rows per page
  const [filterKundenummer, setFilterKundenummer] = useState<string>("");
  const [filterVAD, setFilterVAD] = useState<string>("");
  const [filterKundenavn, setFilterKundenavn] = useState<string>("");

  const [showFilterKundenummer, setShowFilterKundenummer] = useState<boolean>(false);
  const [showFilterVAD, setShowFilterVAD] = useState<boolean>(false);
  const [showFilterKundenavn, setShowFilterKundenavn] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement>(null);

  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchOrkuData(CSharpUtils.T_companyID);
        setOrkuData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOrkuData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle outside clicks to close filters
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tableRef.current && !tableRef.current.contains(event.target as Node)) {
        setShowFilterKundenummer(false);
        setShowFilterVAD(false);
        setShowFilterKundenavn(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage); // Update the current page
  };

  const formatNumber = (num: number): string => {
    const formattedAmount = num.toLocaleString("no-NO", {});
    return formattedAmount.replace(/\s/g, ".");
  };

  // Filter the data
  const filteredData = orkuData.filter((row) => {
    const matchKundenummer = row.customerNumber.toString().includes(filterKundenummer);
    const matchVAD = row.companyId.toLowerCase().includes(filterVAD.toLowerCase());
    const matchKundenavn = row.customerName.toLowerCase().includes(filterKundenavn.toLowerCase());

    // Return rows that match all the filters (if a filter is empty, it doesn't affect the result)
    return matchKundenummer && matchVAD && matchKundenavn;
  });

  // Slice data based on the page and rowsPerPage
  const displayedData = filteredData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "900px" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{
            padding: "16px",
            textAlign: "center",
            
            backgroundColor: "#d30535",
            color: "white",
          }}
        >
          Kunder med direktebestilling (ORKU)
        </Typography>

        {/* Table for displaying data */}
        <Paper sx={{ marginTop: "20px", padding: "16px" }}>
          <TableContainer
            sx={{
              height: "75vh", // Ensure the table is scrollable
              overflowY: "auto",
              borderRadius: "5px",
            }}
            ref={tableRef}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* Kundenummer Filter */}
                  <TableCell align="center">
                    <FilterTextField
                      label="Kundenummer"
                      value={filterKundenummer}
                      onChange={(e) => setFilterKundenummer(e.target.value)}
                      showFilter={showFilterKundenummer}
                      setShowFilter={setShowFilterKundenummer}
                    />
                  </TableCell>

                  {/* VAD Filter */}
                  <TableCell align="center">
                    <FilterTextField
                      label="AD"
                      value={filterVAD}
                      onChange={(e) => setFilterVAD(e.target.value)}
                      showFilter={showFilterVAD}
                      setShowFilter={setShowFilterVAD}
                    />
                  </TableCell>

                  {/* Kundenavn Filter */}
                  <TableCell align="left">
                    <FilterTextField
                      label="Kundenavn"
                      value={filterKundenavn}
                      onChange={(e) => setFilterKundenavn(e.target.value)}
                      showFilter={showFilterKundenavn}
                      setShowFilter={setShowFilterKundenavn}
                    />
                  </TableCell>

                  {/* Kjøp hittil i år */}
                  <TableCell align="right">
                    
                      Kjøp hittil i år
                    
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body for displaying data */}
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                ) : displayedData.length > 0 ? (
                  displayedData.map((row) => (
                    <TableRow key={row.customerNumber + row.companyId}>
                      <TableCell align="left">{row.customerNumber}</TableCell>
                      <TableCell align="left">{row.companyId}</TableCell>
                      <TableCell align="left">{row.customerName}</TableCell>
                      <TableCell align="right">{formatNumber(row.ytdSales)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Pagination
              count={Math.ceil(filteredData.length / rowsPerPage)} // Total number of pages
              page={page} // Current page
              onChange={handleChangePage} // Handle page change
              renderItem={(item) => (
                <PaginationItem
                  sx={{ fontSize: "1.2em" }} // Styling for pagination items
                  {...item}
                />
              )}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Orku;
