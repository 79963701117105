import React, { useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";


import { FilterAlt } from '@mui/icons-material';
import { YearlySalesData,SalgVarerData,KundeSalgData,TransactionData } from '../../../models/NV01SALG';
interface GridViewProps {
  data: (KundeSalgData | TransactionData | YearlySalesData)[];
  kundenummer?: string;
  dataType: string;
}

const GridView: React.FC<GridViewProps> = ({ data, kundenummer, dataType }) => {
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [filterColumn, setFilterColumn] = useState<string | null>(null); // Track which column's filter is active

  const handleFilterChange = (columnName: string, value: string) => {
    setFilters({ ...filters, [columnName]: value });
  };

  const applyFilters = (
    item: KundeSalgData | TransactionData | YearlySalesData
  ) => {
    return Object.keys(filters).every((key) =>
      item[key as keyof (KundeSalgData | TransactionData | YearlySalesData)]
        ?.toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase())
    );
  };

  const handleFilterClick = (columnName: string) => {
    setFilterColumn(columnName === filterColumn ? null : columnName); // Toggle filter text field
  }; 
  if (!Array.isArray(data) || data.length === 0) {
    return <div></div>;
  }

  return (
    <TableContainer component={Paper} style={{ width: "1160px" }}>
      <Table>
        <TableHead>
          <TableRow>
            {dataType === "customerData" ? (
              <>
                {/* Render columns for customerData */}
                <TableCell>Kundenummer</TableCell>        

                <TableCell>Antall</TableCell>
               
                <TableCell>
                Alfa
                <IconButton size="small" onClick={() => handleFilterClick("alpha")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "alpha" && (
                  <TextField
                    size="small"
                    variant="outlined"
                   
                    onChange={(e) => handleFilterChange("alpha", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("alpha")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                Artikkel
                <IconButton size="small" onClick={() => handleFilterClick("item_number")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "item_number" && (
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleFilterChange("item_number", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("item_number")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
               
                <TableCell>
                Varebetegnelse
                <IconButton size="small" onClick={() => handleFilterClick("description")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "description" && (
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleFilterChange("description", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("description")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
          
                <TableCell>
                Varegruppe
                <IconButton size="small" onClick={() => handleFilterClick("article_group")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "article_group" && (
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleFilterChange("article_group", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("article_group")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              </>
            ) : dataType === "transactionData" ? (
              <>
              {/* Render columns for transactionData with filters */}
              <TableCell>
                Dato
                <IconButton size="small" onClick={() => handleFilterClick("date")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "date" && (
                  <TextField
                    size="small"
                    style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("date", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("date")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                Tid
                <IconButton size="small" onClick={() => handleFilterClick("time")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "time" && (
                  <TextField
                    size="small"
style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("time", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("time")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell style={{ width: '150px' }}>
                Tekst
                <IconButton size="small" onClick={() => handleFilterClick("routine")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "routine" && (
                  <TextField
                    size="small"
                    style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("routine", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("routine")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                Ref.nr
                <IconButton size="small" onClick={() => handleFilterClick("ref_number")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "ref_number" && (
                  <TextField
                    size="small"
                    style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("ref_number", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("ref_number")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
             
              <TableCell style={{ width: '140px' }}>
              Kundenr.
                <IconButton size="small" onClick={() => handleFilterClick("customer")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "customer" && (
                  <TextField
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleFilterChange("customer", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("customer")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell >
                Antall
                <IconButton size="small" onClick={() => handleFilterClick("count")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "count" && (
                  <TextField
                    size="small"
                    style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("count", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("count")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                Ident
                <IconButton size="small" onClick={() => handleFilterClick("user")}>
                  <FilterAlt />
                </IconButton>
                {filterColumn === "user" && (
                  <TextField
                    size="small"
                    style={{ width: '150px' }}
                    variant="outlined"
                    onChange={(e) => handleFilterChange("user", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => handleFilterClick("user")}
                          >
                            <FilterAlt />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                  Sign
                  <IconButton size="small" onClick={() => handleFilterClick("init")}>
                    <FilterAlt />
                  </IconButton>
                  {filterColumn === "init" && (
                    <TextField
                      size="small"
                      style={{ width: '150px' }}
                      variant="outlined"
                      onChange={(e) => handleFilterChange("init", e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => handleFilterClick("init")}
                            >
                              <FilterAlt />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  Lager
                  <IconButton size="small" onClick={() => handleFilterClick("lk_code")}>
                    <FilterAlt />
                  </IconButton>
                  {filterColumn === "lk_code" && (
                    <TextField
                      size="small"
                      style={{ width: '150px' }}
                      variant="outlined"
                      onChange={(e) => handleFilterChange("lk_code", e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => handleFilterClick("lk_code")}
                            >
                              <FilterAlt />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  Lokasjon
                  <IconButton size="small" onClick={() => handleFilterClick("location")}>
                    <FilterAlt />
                  </IconButton>
                  {filterColumn === "location" && (
                    <TextField
                      size="small"
                      style={{ width: '150px' }}
                      variant="outlined"
                      onChange={(e) => handleFilterChange("location", e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => handleFilterClick("location")}
                            >
                              <FilterAlt />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </TableCell>
            </>
            ) : (
              <>
                {/* Render columns for other data types */}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.filter(applyFilters).map((item, index) => (
            <TableRow key={index}>
              {/* Render cells dynamically based on the dataType */}
              {dataType === "customerData" ? (
                <>
                  <TableCell>{kundenummer}</TableCell>
                  <TableCell align="center">{(item as KundeSalgData).count}</TableCell>
                  <TableCell>{(item as KundeSalgData).alpha}</TableCell>
                  <TableCell>{(item as KundeSalgData).item_number}</TableCell>
                  <TableCell>{(item as KundeSalgData).description}</TableCell>
                  <TableCell>{(item as KundeSalgData).article_group}</TableCell>
                </>
              ) : dataType === "transactionData" ? (
                <>
                <TableCell>{(item as TransactionData).date}</TableCell>
<TableCell>{(item as TransactionData).time}</TableCell>
<TableCell>{(item as TransactionData).routine}</TableCell>
<TableCell>{(item as TransactionData).ref_number}</TableCell>
<TableCell >{(item as TransactionData).customer}</TableCell>
<TableCell align="center">
  {(item as TransactionData).sign}{Math.abs((item as TransactionData).count)}
</TableCell>
<TableCell>{(item as TransactionData).user}</TableCell>
<TableCell>{(item as TransactionData).init}</TableCell>
<TableCell>{(item as TransactionData).lk_code}</TableCell>
<TableCell>{(item as TransactionData).location}</TableCell>

                </>
              ) : (
                <></>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GridView;
