import fileDownload from 'js-file-download';
import { put } from 'redux-saga/effects';
import { APIgetNV03ExcelData, APIgetNV03ListItem } from '../../felles/APICalls_NV03';
import PageImpl from '../../models/PageImpl';
import * as actions from '../actions';
import * as actionsNV03 from '../actions/indexNV03';

//
export function* GetNV03VALFItemListSaga(payload) {

  // console.log('payload.formData' , payload.formData);
  
  //
  try {
    yield put(actions.setIsloading(true));
    const response = payload.formData.exportToExcel ? yield APIgetNV03ExcelData(payload.formData) : yield APIgetNV03ListItem(payload.formData);
    //
    if (response && response.data) {
      if (payload.formData.exportToExcel)
        yield fileDownload(response.data, 'NV03-list__' + new Date().getTime() + '.xlsx');
      else {
        yield put(actionsNV03.NV03VALFListeGetSuccess(response.data as PageImpl));
      }
      yield put(actions.setIsloading(false));
    }
  } catch (error) {
    yield put(actionsNV03.NV03VALFListeGetFailed(error));
  } finally {
    yield put(actions.setIsloading(false));
  }
}
