
import * as actionTypesNV01SALG from "./actionTypesNV01";
import { ProductSales,SalgVarerData,KundeSalgData, KundeData,AllTransactionResponse} from "../../models/NV01SALG";

export const fetchSalgDataRequest = (firmaid: number,alfa: string, artikkel: string) => ({

  type: actionTypesNV01SALG.SALG_SISTE_4_AAR_GET,
  payload: {
    firmaid,
    alfa,
    artikkel
  },
});
export const fetchSalgDataSuccess = (data: ProductSales) => ({
  type: actionTypesNV01SALG.SALG_SISTE_4_AAR_SUCCESS,
  payload: data,
});

export const fetchSalgDataFailure = (error: string) => ({
  type: actionTypesNV01SALG.SALG_SISTE_4_AAR_FAILURE,
  payload: error,
});

// New actions for SALGVAREDATA
export const fetchSalgVareDataRequest = (firmaid: number, alfa: string, artikkel: string) => ({
  type: actionTypesNV01SALG.SALGVAREDATA_GET,
  payload: {
    firmaid,
    alfa,
    artikkel,
  },
});

export const fetchSalgVareDataSuccess = (data: SalgVarerData) => ({
  type: actionTypesNV01SALG.SALGVAREDATA_SUCCESS,
  payload: data,
});

export const fetchSalgVareDataFailure = (error: string) => ({
  type: actionTypesNV01SALG.SALGVAREDATA_FAILURE,
  payload: error,
});

// New actions for KUNDE_SALG_DATA
export const fetchKundeSalgDataRequest = (kundenummer: string, year: string, firmaid: number) => ({
  type: actionTypesNV01SALG.KUNDE_SALG_DATA_GET,
  payload: {
    kundenummer,
    year,
    firmaid,
  },
});

export const fetchKundeSalgDataSuccess = (data: KundeSalgData) => ({
  type: actionTypesNV01SALG.KUNDE_SALG_DATA_SUCCESS,
  payload: data,
});

export const fetchKundeSalgDataFailure = (error: string) => ({
  type: actionTypesNV01SALG.KUNDE_SALG_DATA_FAILURE,
  payload: error,
});

// New actions for KUNDE_DATA
export const fetchKundeDataRequest = (firmaid:number,kundenummer: string,kundenavn:string) => ({
  type: actionTypesNV01SALG.KUNDE_DATA_GET,
  payload: {
    firmaid,
    kundenummer,
    kundenavn
  },
});

export const fetchKundeDataSuccess = (data: KundeData) => ({
  type: actionTypesNV01SALG.KUNDE_DATA_SUCCESS,
  payload: data,
});

export const fetchKundeDataFailure = (error: string) => ({
  type: actionTypesNV01SALG.KUNDE_DATA_FAILURE,
  payload: error,
});
// New actions for Transaction_DATA

export const fetchTRANSAKSJONERRequest = (firmaid: number,alfa: string, artikkel: string,page:number,pagesize:number) => ({
  type: actionTypesNV01SALG.ALL_TRANSAKSJONER_GET,
  payload: {
    firmaid,
    alfa,
    artikkel,
    page,
    pagesize
  },
});

export const fetchTRANSAKSJONERSuccess = (data: AllTransactionResponse) => ({
  type: actionTypesNV01SALG.ALL_TRANSAKSJONER_SUCCESS,
  payload: data,
});

export const fetchTRANSAKSJONERFailure = (error: string) => ({
  type: actionTypesNV01SALG.ALL_TRANSAKSJONER_FAILURE,
  payload: error,
});

export const clearData = () => ({
  type: actionTypesNV01SALG.CLEAR_DATA,
});