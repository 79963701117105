import { Theme } from '@mui/material';
import SVGHelpRed from '../../../components/SVGHelpRed';
import SVGMail from '../../../components/SVGMail';
import SVGPhone from '../../../components/SVGPhone';
import C from '../../../felles/UtilsCSS';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) =>
({
  root: { padding: 0, },
  gridItem: {
    display: 'flex', padding: '13px 0',
    [C.r(theme, C.XXS, C.XXX)]: { padding: '7px 0', fontSize: '.9em' },
    [C.r(theme, C.XS, C.XSX)]: { padding: '7px 0', fontSize: '.9em' },
    [C.r(theme, C.SM, C.SMX)]: { padding: '7px 0', fontSize: '.9em' },
    [C.r(theme, C.MD, C.MDX)]: { padding: '7px 0', fontSize: '.9em' },
    [C.r(theme, C.LG, C.LGX)]: { padding: '5px 0', fontSize: '.9em' },
    [C.r(theme, C.XL, C.XLX)]:{ padding: '5px 0', fontSize: '.9em' },
    [C.r(theme, C.XXL, C.YLX)]: { padding: '5px 0', fontSize: '.9em' },
    [C.IPAD_10]: { fontSize: '.8em', paddingLeft: 16, paddingBottom: 0, },
    [C.SAMSUNG]: { fontSize: '.8em', paddingTop: 6, margin: 0, },

  },
  logo: {
    color: '#333',
    opacity: 0.7,
    padding: 0,
    [C.r(theme, C.XXS, C.XXX)]: { width: 15, margin: '2px 5px 0px -11px' },
    [C.r(theme, C.XS, C.XSX)]: { width: 15, margin: '2px 5px 0px -11px' },
    [C.r(theme, C.SM, C.SMX)]: { width: 15, margin: '2px 5px 0px -8px' },
    [C.r(theme, C.MD, C.MDX)]: { width: 15, margin: '2px 5px 0px -8px' },
    [C.r(theme, C.LG, C.LGX)]: { width: 25, margin: '2px 5px 0px -8px' },
    [C.r(theme, C.XL, C.XLX)]: { width: 25, margin: '2px 5px 0px -8px' },
    [C.r(theme, C.XXL, C.YLX)]: { width: 25, margin: '2px 5px 0px -8px' },
    //[C.IPAD_10]: { base: {}, },
    //[C.SAMSUNG]: { base: {}, },
  },
  gridItemTxt: {
    paddingLeft: '1px', color: '#171b3d', width: '90%',
    [C.IPAD_10]: { fontSize: '1.2em', },
    [C.SAMSUNG]: { marginTop: 0, fontSize: '1em' },
  },
  kontakt: { fontWeight: 'bold', },
})
);
/* interface Props {  children: React.ReactElement;  open: boolean;  value: number;} */
export default function CSupport() {
  const { classes } = useStyles();

  
  /*
  const handleHelpClicked = (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.open('https://www.vitecsoftware.com/no/produktomrade/auto/support/', 'Support - Vitec', 'resizable,scrollbars,status');
  };
  */

  return (
    <div className={classes.root}>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          {' '}
          <SVGMail width="30" />
        </div>

        <div>
          {' '}
          <a className={classes.gridItemTxt} href={'mailto:support.autodata@vitecsoftware.com'}>
            support.autodata@vitecsoftware.com
          </a>
        </div>
      </div>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          <SVGHelpRed />
        </div>
        <div className={classes.gridItemTxt}>
          <a className={classes.gridItemTxt} href={'https://vitec-autodata.pureservice.com/login'} target="_new">
            Selvbetjeningsportal
          </a>
        </div>
      </div>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          <SVGHelpRed />
        </div>
        <div className={classes.gridItemTxt}>
          <a className={classes.gridItemTxt} href={'https://www.vitecsoftware.com/no/produktomrade/auto/support/'} target="_new">
            Hjelp
          </a>
        </div>
      </div>
      <div className={classes.gridItem}>
        <div className={classes.logo}>
          <SVGPhone width="30" />
        </div>
        <div className={classes.gridItemTxt}>
          23 17 20 30 - Telefontid mandag til fredag 08:00 – 16:00
        </div>
      </div>
    </div>
  );
}
