import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "tss-react/mui";
import Papyrus from '../assets/images/background-body_1.png';
import * as label from '../autobuild_version';
import BugRepportHandler from '../pages/error/BugRepportHandler';
import Utils from '../felles/Utils';
import * as actions from '../store/actions/index';
import { AppState } from '../store/rootReducer';
import ButtonVitec from './ButtonVitec';
import LPNavigationProfile from "./LPNavProfile";
import RightClickMenu from "./RightClickMenu";

const useStyles = makeStyles()((theme) => ({
  root: { display: 'flex', height: '100vh', width: '100vw' },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },

  appLayout: { margin: 0, padding: 0, width: '100vw', height: '100vh', },
  bgHeader: {
    background: 'rgba(150,100,103, 1)',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    display: 'block',
    backgroundImage: `url(${Papyrus})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    opacity: 0.3,
    pointerEvents: 'none',
  },
  version: { position: 'fixed', color: '#333', right: '15px', bottom: '5px', margin: '2px', opacity: 0.2, },
  debug: {
    position: 'absolute',
    display: ' inline-block',
    verticalAlign: ' top',
    fontSize: '1.5em',
    color: '#f3f3f3',
    top: 0,
    right: 0,
    width: 1050,
  },
  lowerM: {
    position: 'fixed', color: '#333', right: '15px',

  },
})
);

const Layout = ({ children }) => {
  const style = { width: '100%', margin: 0, padding: 0 };
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isLoading: boolean = useSelector((state) => (state as AppState).utils.isLoading);
  const dialogMsg: string = useSelector((state) => (state as AppState).utils.msg);
  const dialogTitle: string = useSelector((state) => (state as AppState).utils.title);
  const isSimpleDialogueOpen: boolean = useSelector((state) => (state as AppState).utils.isSimpleDialogueOpen);
  const apiVersion = 'A' + Utils.T_apiVersion;
  const recordingState: number = useSelector((state) => (state as AppState).utils.recordingState);
  const reportingLog: any[] = useSelector((state) => (state as AppState).utils.recordingLog);
  const isDebugEnabled: boolean = useSelector((state) => (state as AppState).utils.isDebugEnabled);

  const handleDiagClose = () => {
    dispatch(actions.setIsloading(false));
    dispatch(actions.setSimpledialogOpeningState(false));
    dispatch(actions.setMsg(''));
    dispatch(actions.setTitle(''));
  };

  const handleStartClickedBtn = () => {
    if (recordingState === 0) {
      dispatch(actions.setRecordingState(1));
      dispatch(actions.updateRecordingLog([<p>Click above to view it in a browser if not displayed correctly.</p>]));
    }
    else if (recordingState > 0) {
      dispatch(actions.setRecordingState(0));
    }
  }

  const handleSubmitClickedBtn = () => {
    dispatch(actions.setRecordingState(2));
  }

  const handleResetClickedBtn = () => {
    dispatch(actions.setRecordingState(0));
    dispatch(actions.updateRecordingLog([]));

    if (isDebugEnabled && recordingState === 0) {
      dispatch(actions.switchDebugMode());
    }
  }

  return (
    <>
      <div id="no-portrait">
        <div id="chg-landscape">Vennligst bytt til liggende modus</div>
      </div>
      {/*' tabIndex={0} onKeyDown={(e) => handlePressedKey(e)} '*/}
      <div className={classes.appLayout}>
        <div className={classes.version}>
          {'F' + label?.version + ' | ' + apiVersion}
        </div>
        <div className={classes.bgHeader} style={{ zIndex: -1251 }}></div>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={isSimpleDialogueOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDiagClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="app-layout-content" style={style}>
          {children}
        </div>
        <div className={classes.lowerM}>
          <LPNavigationProfile />
        </div>
      </div>
      <RightClickMenu />
      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>  {
        /*QTR === 1 && */ isDebugEnabled ?
          <>{/*recordingState + ' --- ' + reportingLog + ' --- ' + reportingLog.length*/}
            <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={() => handleStartClickedBtn()} _name={'record'}
              _label={recordingState === 0 ? 'Start recording' : 'Stop recording'} _width={200} _height={35} _padding={5} _margin={0} />
            <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={handleResetClickedBtn} _name={'submit'}
              _label={isDebugEnabled && recordingState === 0 ? 'Quit debug' : 'Reset'} _width={150} _height={35} _padding={5} _margin={0} />
            <ButtonVitec _type={'submit'} _fontsize={'1em'} _onclick={handleSubmitClickedBtn} _name={'submit'}
              _label={'Submit aktiviteter'} _width={200} _height={35} _padding={10} _margin={0} disabled={reportingLog?.length === 0} />
          </>
          : null}
      </div>
      {recordingState > 1 ? <BugRepportHandler /> : null}
    </>
  );
};

export default Layout;
