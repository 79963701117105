import React, { forwardRef } from "react";
import TextField from "@mui/material/TextField";

interface InputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const CustomInput = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, placeholder }, ref) => (
    <TextField
      value={value}
      onChange={onChange}
      label={placeholder}
      inputRef={ref}
      variant="outlined"
      style={{ marginRight: "10px" }}
      fullWidth
    />
  )
);

export default CustomInput;
