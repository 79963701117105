import { alpha, Checkbox, FormControlLabel, Pagination, PaginationItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Grid, TextField } from '@mui/material';
import Utils from '../../../../felles/Utils';
import * as actions from '../../../../store/actions/index';
import * as actionsNV03 from '../../../../store/actions/indexNV03';
import { AppState } from '../../../../store/rootReducer';
import NV03VALFTableHead from './NV03VALFTableHead';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ButtonVitec from '../../../../components/ButtonVitec';
import pageImpl from '../../../../models/PageImpl';
import NV03Valf, { NV03Headers } from '../../../../models/NV03Valf';

//
const useStyles = makeStyles()((theme: Theme) => ({
  root: { margin: 20, fontSize: '1em !important', color: '#fff', },
  //
  container: {
    height: `calc(100vh - 82px)`,
    //
    border: 'none',
    "&.label.Mui-focused ": { color: 'white', }
  },
  table: { minWidth: 700, fontSize: 200 },
  tableCell: { margin: 'auto', textAlign: 'center', fontSize: 16, },
  spacer: { flex: "1 1 100%" },
  actions: { color: theme.palette.text.secondary },
  iconWrapper: { display: 'flex' },
  tableWrapper: { display: 'flex' },
  ba: { border: 'none', },
  bb: { color: '#2E2D2C' },
  //
  tField: {
    display: 'flex',
    // border: '2px solid orange',
    '& label': { fontSize: 20, height: 45, lineHeight: .8, backgroundColor: '2px solid #4f4d4c', },
    height: 45,
    //'& label.Mui-focused': { top: 12, animationIterationCount: 1, transform: 'translate(0, -10px) scale(1)', fontSize: '.75em', color: '#d30535', },
    //'& .MuiInputLabel-formControl': { top: 11, left: 6, position: 'absolute', transform: 'translate(0, 0) scale(1)', color: '#d30535', },
    '& .MuiInput-underline:after': { height: 45, width: 0, fontSize: 20 },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": { fontSize: 20, border: '2px solid #4f4d4c', },
    // (Note: space or no space after `&` matters. See SASS "parent selector".)
    '& .MuiOutlinedInput-root': {
      color: 'red',
      height: 47,
      fontSize: 20,
      //border: '2px solid white',
      // - The Input-root, inside the TextField-root
      '& fieldset': {/* - The <fieldset> inside the Input-root */ },
      '&:hover fieldset': {},
      '&.Mui-focused fieldset': {},
    },
  },
}),
);

//
type FormInputs = {
  alfa: string, artikkel: string, varebetegnelse: string, varegruppe: string, pageNb: number, size: number
  , sort: string, ckBehold: boolean, ckVisAlle: boolean, cVeilpris: boolean, exportToExcel: boolean
};

//
const NV03VALFFormHook = (props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { page, isLoadingComponent } = useSelector((state: AppState) => ({
    page: state.NV03.page as pageImpl,
    isLoadingComponent: state.utils.isLoading,
  }));
  const isBeholdningChecked: boolean = useSelector((state) => (state as AppState).NV03.isBeholdningChecked);
  const isVeilPrisChecked: boolean = useSelector((state) => (state as AppState).NV03.isVeilPrisChecked);
  const isVisAlleLagerChecked: boolean = useSelector((state) => (state as AppState).NV03.isVisAlleLagerChecked);
  //
  const emptyRows = 100;
  //
  const [defaultPageCount, setDefaultPageCount] = useState(10);
  //
  const methods = useForm<FormInputs>({
    defaultValues: {
      alfa: null || '',
      artikkel: null || '',
      varebetegnelse: null || '',
      varegruppe: null || '',
      pageNb: null || 0,
      size: null || 100,
      sort: null || 'artikkel',
      ckBehold: false,
      ckVisAlle: false,
      cVeilpris: false,
      exportToExcel: false,
    },
  });

  //
  const nV03Headers: NV03Headers[] = useSelector((state) => (state as AppState).NV03.nV03Headers);

  //
  useEffect(() => {
    document.body.removeEventListener('touchmove', Utils.preventDefault);
    if (!isLoadingComponent && page.content?.length === 0) {
      // Populates the gridlist if empty
      for (let index = 0; index < emptyRows; index++) {
        page.content.push(new NV03Valf(index, 'O', '-', '', '', ' '))
      }
      // 
    }
    page.content?.length > 0 ? setDefaultPageCount(page.totalPages - 1) : setDefaultPageCount(10);
    dispatch(actions.setSandwichButtonStateClicked(false));
    //Give focus to the first field
    const inputField: HTMLInputElement | null = document.querySelector('input[name="alfa"]');
    if (inputField) {
      inputField.focus();
    }
  }, [page.content, nV03Headers, dispatch]);

  //
  const handleKeyPress = (event) => {
    //
    const { key } = event;
    const actionSource = key || event.target.value;
    //setKey
    switch (true) {
      case actionSource === 'Enter' || actionSource === 'Export':
        event.preventDefault();
        actionSource === 'Export' ? methods.setValue('exportToExcel', true) : methods.setValue('exportToExcel', false);
        methods.handleSubmit(onSubmit)();
        break;
      default:
        break;
    }
  };

  const onSubmit = (data: NV03Valf | any) => {
    if ((data?.alfa !== '' && data?.alfa.length === 3) || data?.alfa === '') {
      data.nV03Headers = nV03Headers;
      dispatch(actionsNV03.NV03VALFListeGet(data));
      methods.setValue('exportToExcel', false) 
    }
     
  };

  const pagechange = (e: any, pageValue = 0) => {
    methods.setValue('pageNb', pageValue)
    methods.handleSubmit(onSubmit)();
  };

  const setSort = (value: string | NV03Headers[]) => {
     methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <form className={classes.root} onKeyUp={handleKeyPress}>
        <input  {...methods.register("pageNb")} hidden />
        <input  {...methods.register("size")} hidden />
        <input  {...methods.register("sort")} hidden />
        <input  {...methods.register("exportToExcel")} hidden />
        <Paper elevation={0} sx={{
          backgroundColor: alpha('#fff', .7)
          /* backgroundColor: alpha('#2E2D2C', .8), color: '#fff', border: 'none',*/
        }}
        >
          <TableContainer className={classes.container} sx={{ borderRadius: '5px' }} >
            <Grid container width={'100%'} height={`calc(100vh - 82px)`} style={{ maxHeight: '100vh' }}>
              {/*********************************************************** HEADER *****************************************************************/}
              <Grid container height={'170px'} justifyContent='left' className={classes.ba} >
                <Grid container height={'30px'} className={classes.bb} >
                  <Grid item sx={{ paddingLeft: '18px', paddingTop: '7px', fontSize: '2em' }} p={1}>Varesøk</Grid>
                </Grid>
                <Grid container height={'30px'} className={classes.bb} rowSpacing={0} columnSpacing={3} direction="row"
                  justifyContent="left" alignItems="center">
                  <Grid item />
                  <Grid item xs={1}>
                    <TextField label="Alfa" className={classes.tField} inputProps={{ maxLength: 3, shrink: 'false' }}  {...methods.register("alfa", { required: false })}
                      onChange={(e) => { methods.setValue('alfa', (e.target as any).value.toUpperCase()); }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField label="Artikkel" className={classes.tField} {...methods.register("artikkel", { required: false })}
                      onChange={(e) => { methods.setValue('artikkel', (e.target as any).value); }} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField label="Varebetegnelse" className={classes.tField} {...methods.register("varebetegnelse", { required: false })}
                      onChange={(e) => { methods.setValue('varebetegnelse', (e.target as any).value); }} />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField label="Varegruppe" className={classes.tField} {...methods.register("varegruppe", { required: false })}
                      onChange={(e) => { methods.setValue('varegruppe', (e.target as any).value); }} />
                  </Grid>
                  <Grid item>
                    <div className={classes.actions} style={{ marginBottom: 10 }}>
                      <ButtonVitec _onclick={handleKeyPress} _width={135} _height={37} _type={'button'}
                        _label={'HENT (ENTER)'} _value={'Enter'} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.actions} style={{ marginBottom: 10 }}>
                      <ButtonVitec _onclick={handleKeyPress} _width={210} _height={37} _type={'button'}
                        _label={'EKSPORTERE TIL EXCEL'} _value={'Export'} />
                    </div>
                  </Grid>
                  <Grid item xs="auto" />
                </Grid>
                {/*******************************************************************************************************************/}
                {/*************************************************** CHECKBOX ******************************************************/}
                {/*******************************************************************************************************************/}
                {/*******************************************************************************************************************/}

                <Grid container height={'10px'} className={classes.bb} rowSpacing={0} columnSpacing={3} direction="row"
                  justifyContent="left" alignItems="center">
                  <Grid item />
                  <Grid item xs={1}>
                    <FormControlLabel sx={{ width: '140px', paddingTop: '10px' }} label={"Beholdning"}
                      control={<Controller control={methods.control}
                        name="ckBehold" render={({ field: { onChange, value } }) => (
                          <Checkbox onChange={(event, item) => {
                            onChange(item);
                            dispatch(actionsNV03.setIsBeholdningCheckboxState(!value))
                            methods.handleSubmit(onSubmit)();
                          }} name={'ckBehold'} checked={value} />)}
                      />} />
                  </Grid>
                  <Grid item xs={1.1}>
                    <FormControlLabel sx={{ width: '240px', paddingTop: '10px' }} label="Vis alle lager" control={<Controller control={methods.control}
                      name="ckVisAlle" render={({ field: { onChange, value } }) => (
                        <Checkbox onChange={(event, item) => {
                          onChange(item);
                          dispatch(actionsNV03.setIsVisAlleLagerCheckboxState(!value))
                          methods.handleSubmit(onSubmit)();
                        }} name={'ckVisAlle'} checked={value || false} />)}
                    />} />
                  </Grid>
                  <Grid item xs="auto">
                    <FormControlLabel sx={{ width: '140px', paddingTop: '10px' }} label="Veil. pris"
                      control={<Controller control={methods.control}
                        name="cVeilpris" render={({ field: { onChange, value } }) => (
                          <Checkbox onChange={(event, item) => {
                            onChange(item);
                            dispatch(actionsNV03.setIsVeilPrisCheckboxState(!value))
                            methods.handleSubmit(onSubmit)();
                          }} name={'cVeilpris'} checked={value} />)}
                      />} />
                  </Grid>
                  <Grid item xs="auto" />
                </Grid>
                <Grid container height={'5px'} alignItems="center" justifyContent='left' className={classes.bb} />
              </Grid>
              {/*******************************************************************************************************************/}
              {/***************************************************** CONTENT *****************************************************/}
              {/*******************************************************************************************************************/}
              {/*******************************************************************************************************************/}
              <Grid container height={`calc(100% - 210px)`} alignItems="top" justifyContent='center' className={classes.ba}
                sx={{
                  maxHeight: '100vh', overflow: 'auto',
                  "&::-webkit-scrollbar": { width: "20px" },
                  "&::-webkit-scrollbar-track": { backgroundColor: alpha('#000', 0.2) },
                  "&::-webkit-scrollbar-thumb": { backgroundColor: "#706c6a", },
                }}
              >
                <Grid item width={'100%'} >
                  <Table stickyHeader className={classes.table} size="small" aria-labelledby="tableTitle" >
                    <NV03VALFTableHead onRequestSort={setSort} />
                    <TableBody sx={{ width: '100%', border: '10px solid black' }}>
                      {
                        Array.isArray(page.content) && page.content?.length > 0 ? (
                          page.content.map((n: NV03Valf) => (
                            <TableRow
                              aria-checked={false}
                              tabIndex={-1}
                              key={n.id}
                              selected={false}
                              sx={{
                                "&:nth-of-type(even)": {
                                  background: '#fff'
                                },
                                "&:nth-of-type(odd)": {
                                  background: '#f3f3f3'
                                }
                              }}
                            >
                              <TableCell className={classes.tableCell}>{n.alfa}</TableCell>
                              <TableCell className={classes.tableCell}>{n.artikkelNummer}</TableCell>
                              <TableCell className={classes.tableCell}>{n.vareSpes}</TableCell>
                              <TableCell className={classes.tableCell}>{n.vareGruppe}</TableCell>
                              <TableCell className={classes.tableCell}>{n.utSalgPris}</TableCell>
                              {isVeilPrisChecked ? <TableCell className={classes.tableCell}>{n.veilPris}</TableCell> : null}
                              <TableCell className={classes.tableCell}>{n.lokasjon}</TableCell>
                              {isBeholdningChecked ? <TableCell className={classes.tableCell}>{n.behold}</TableCell> : null}
                              {isVisAlleLagerChecked ? <TableCell className={classes.tableCell}>{n.lktotal}</TableCell> : null}
                              {isVisAlleLagerChecked ? <TableCell className={classes.tableCell}>{n.behTrondheim}</TableCell> : null}
                              {isVisAlleLagerChecked ? <TableCell className={classes.tableCell}>{n.behbergen}</TableCell> : null}
                              <TableCell className={classes.tableCell}>{n.auto}</TableCell>
                              <TableCell className={classes.tableCell}>{n.merknad}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} className={classes.tableCell} scope="row" padding="none">
                              No data available
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody></Table> </Grid>
              </Grid>
              {/*********************************************************** FOOTER *****************************************************************/}
              <Grid container height={"30px"} alignItems="center" justifyContent='center'
                className={classes.bb} rowSpacing={0} columnSpacing={0}
              >
                <Grid item  >
                  <Pagination sx={{
                    color: 'white',
                    "& .Mui-selected": { backgroundColor: "pink" }
                  }}
                    count={defaultPageCount}
                    onChange={(e, value) => pagechange(e, value)}
                    onKeyUp={(e) => pagechange(e)}
                    renderItem={(item) => (
                      <PaginationItem sx={{ fontSize: '1.2em', }}
                        {...item}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TableContainer>
        </Paper>
      </form>
    </FormProvider>
  );
};

export default NV03VALFFormHook;