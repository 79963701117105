export default class NV03Valf {
    private _id: number;
    private _firmKode: string;
    private _alfa: string;
    private _artikkelNummer: string;
    private _vareSpes: string;
    private _vareGruppe: string;
    private _utSalgPris: string;
    private _veilPris: string;
    private _lokasjon: string;
    private _behold: string;
    private _lktotal: string;
    private _behstavanger: string;
    private _behTrondheim: string;
    private _behtromso: string;
    private _behkommisjon: string;
    private _behskien: string;
    private _behbergen: string;
    private _auto: string;
    private _merknad: string;
    private _nV03Headers: NV03Headers[] = [];

    constructor(id = 0, firmKode = '', alfa = '', artikkelNummer = '', vareGruppe = '', vareSpes = '', utSalgPris = '', veilPris = '', lokasjon = '', behold = '',
        lktotal = '',
        behstavanger = '',
        behTrondheim = '',
        behtromso = '',
        behkommisjon = '',
        behskien = '',
        behbergen = '',
        auto = '',
        merknad = '',
        nV03Headers: NV03Headers[] = []) {
        this._id = id;
        this._firmKode = firmKode;
        this._alfa = alfa;
        this._artikkelNummer = artikkelNummer;
        this._vareSpes = vareSpes;
        this._vareGruppe = vareGruppe;
        this._utSalgPris = utSalgPris;
        this._veilPris = veilPris;
        this._lokasjon = lokasjon;
        this._behold = behold;
        this._lktotal = lktotal;
        this._behstavanger = behstavanger;
        this._behTrondheim = behTrondheim;
        this._behtromso = behtromso;
        this._behkommisjon = behkommisjon;
        this._behskien = behskien;
        this._behbergen = behbergen;
        this._auto = auto;
        this._merknad = merknad;
        this._nV03Headers = nV03Headers;
    }

    get id(): number {
        return this._id;
    }

    get firmKode(): string {
        return this._firmKode.toUpperCase();
    }

    get alfa(): string {
        return this._alfa;
    }

    get artikkelNummer(): string {
        return this._artikkelNummer;
    }

    get vareGruppe(): string {
        return this._vareGruppe;
    }

    get vareSpes(): string {
        return this._vareSpes;
    }

    get utSalgPris(): string {
        return this._utSalgPris;
    }


    get veilPris(): string {
        return this._veilPris;
    }

    get lokasjon(): string {
        return this._lokasjon;
    }

    get behold(): string {
        return this._behold;
    }

    get lktotal(): string {
        return this._lktotal;
    }

    get behstavanger(): string {
        return this._behstavanger;
    }

    get behTrondheim(): string {
        return this._behTrondheim;
    }
    get behtromso(): string {
        return this._behtromso;
    }

    get behkommisjon(): string {
        return this._behkommisjon;
    }

    get behskien(): string {
        return this._behskien;
    }

    get behbergen(): string {
        return this._behbergen;
    }

    get auto(): string {
        return this._auto;
    }

    get merknad(): string {
        return this._merknad;
    }

    get nV03Headers(): NV03Headers[] {
        return this._nV03Headers;
    }
}

export interface NV03Headers {
    id: string;
    numeric: boolean;
    label: string;
    width: string;
    sortable: false | true;
    orderBy: false | true;
    order: 'desc' | 'asc';
    filter: '';
}

