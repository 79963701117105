import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
    vitecBt: {
        display: 'inline-block',
        color: '#fff',
        borderRadius: 3,
        background: '#d30535',
        boxShadow: '0 0 5px -1px rgba(0,0,0,0.2)',
        cursor: 'pointer',
        verticalAlign: 'middle',
        width: '100px',
        padding: '5px',
        marginTop: '13px',
        textAlign: 'center',
        '&:hover': {
            background: '#961932',
            color: 'white',
            cursor: 'pointer',
        },
        '&:active': {
            color: 'white',
            background: '#cd5a6e',
        },
        '&:disabled': {
            color: 'white',
            background: '#e3e3e3',
        },
    },
    customTooltip: { background: '#cd5a6e', fontSize: '.9em', fontWeight: 100, color: 'white', margin: 0, marginTop: 10, marginLeft: -5, },
    customArrow: { color: '#cd5a6e', },
})
);

export default function ButtonVitec(props) {
    const { classes } = useStyles();
    //
    return (<>
        <Tooltip classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow
        }} title={props._tooltip} placement={"top"} arrow>
            <Button type={props && props._type ? props._type : 'button'}
                className={classes.vitecBt}
                onClick={e => (typeof props._onclick === 'function' ? props._onclick(e) : null)} 
                disabled={props.disabled}
                style={{ fontSize: props._fontsize, width: props._width, height: props._height, margin: props._padding }}
                name={props._name}
                value={props._value}
            >{props._label}</Button>
        </Tooltip>
    </>
    );
}
