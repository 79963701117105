import { Card, CardContent, CardHeader, Theme, Typography, alpha } from '@mui/material';
import { red } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Utils from '../felles/Utils';
import C from '../felles/UtilsCSS';
import MenuEl from '../models/MenuEl';
import MenuItemTarget from '../models/MenuItemTarget';
import * as actions from '../store/actions/index';

const useStyles = makeStyles()((t: Theme) => ({
  root: {
    border: 'none',
    //borderRadius: 5,
    //borderTopLeftRadius:5,
    //borderTopRightRadius:5,
    height: '100%',
    //width: 'auto',
    padding: 0,
    margin: 0,
    '.MuiCardContent-root': { padding: 0, margin: 0, },
    '.MuiCardHeader-title': { lineHeight: '25px', fontSize: '1.4em', },
  },
  cardContent: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    lineHeight: '25px',
    padding: 0,
    '&:last-child': { paddingBottom: 0 },
  },
  cardHeader: {
    backgroundColor: '#2E2D2C',
    color: '#fff',
    //border: '1px solid #171b3d',
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 20,
    fontSize: '1em',
    [C.IPAD_10]: { fontSize: '.8em' },
    [C.SAMSUNG]: { fontSize: '.8em' },
  },
  subtitle: { fontWeight: 'bold', fontSize: '1em', color: '#d30535' },
  code: { color: '#2E2D2C', fontSize: '1.1em' },
  content: {

    backgroundColor: '#fff',
    padding: 9,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [C.r(t, C.XXS, C.XXX)]: { fontSize: '.9em' },
    [C.r(t, C.XS, C.XSX)]: { fontSize: '.9em' },
    [C.r(t, C.SM, C.SMX)]: { fontSize: '.9em' },
    [C.r(t, C.MD, C.MDX)]: { fontSize: '.9em' },
    [C.r(t, C.LG, C.LGX)]: { fontSize: '1em' },
    [C.r(t, C.XL, C.XLX)]: { fontSize: '1em' },
    [C.r(t, C.XXL, C.YLX)]: { fontSize: '1.4em' },

    [C.IPAD_10]: { fontSize: '.8em' },
    [C.SAMSUNG]: { fontSize: '.8em' },
    // borderTop: '1px solid #2E2D2C',
    '&:hover': { cursor: 'pointer', backgroundColor: '#f6f6f6' },
    '&:active': { cursor: 'pointer', backgroundColor: alpha('#d30535', 0.2), color: '#f6f6f6' },
    '&:first-of-type': { borderTopLeftRadius: 0, borderTopRightRadius: 0, },
  },
  media: { height: 0 },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: t.transitions.create('transform', { duration: t.transitions.duration.shortest, }),
  },
  expandOpen: { transform: 'rotate(180deg)' },
  avatar: { backgroundColor: red[500] },
}));

export default function LPContentCardVSE(props) {
  const { classes } = useStyles();
  const title = props.title;
  const data = props.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const handleItemClicked = (event, menuItemTarget: MenuItemTarget) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(actions.setSandwichButtonStateClicked(false));
    dispatch(actions.setLocationPath(Utils.getLocationPatchFromMenuItem(menuItemTarget, menuItemTarget.code, menuItemTarget.title)));

    console.log(+menuItemTarget.tilpasset);

    // The plus + sign convert menuItemTarget.tilpasset to a number
    if (+menuItemTarget.tilpasset === 1) {
      navigate(Utils.HOMEPAGE_CONTEXT + '/' + menuItemTarget.code);
    } else if (+menuItemTarget.tilpasset === 2) {
      navigate(Utils.HOMEPAGE_CONTEXT + '/' + menuItemTarget.code);
    } else {
      if ((Utils.HOST_NAME.includes('vm.') || Utils.HOST_NAME.includes('beta.')) && +menuItemTarget.tilpassetBeta === 1) {
        navigate(Utils.HOMEPAGE_CONTEXT + '/' + menuItemTarget.code);
      } else
        dispatch(actions.transGet(menuItemTarget));
    }
  };

  function handleRightClick(e, menuItem) {
    const clickX = e.clientX;
    const clickY = e.clientY;
    dispatch(actions.setContextMenuVisible(true));
    dispatch(actions.setContexMenuX(clickX));
    dispatch(actions.setContexMenuY(clickY));
    dispatch(actions.setTilpasset(menuItem.tilpasset));
    dispatch(actions.setTransidRedirect(menuItem.code));
  }

  const renderMenuItems = () => {
    const JSXItemElemments: any = [];
    let count = 0;
    data.forEach((el: MenuEl) => {


      //console.log( el.code + 'el.kodeSynlig === 0: ' + (el.kodeSynlig === 0) + 'el.labelSynlig === 0: ' + (el.labelSynlig === 0));
      
      if (+el.kodeSynlig !== 0 || +el.labelSynlig !== 0) {

        JSXItemElemments.push(
          <Typography title={el.description}
            onClick={(event) => handleItemClicked(event, new MenuItemTarget(el.rootName, el.parentName, el.name, el.code, el.tilpasset, el.tilpassetBeta
              , el.kodeSynlig, el.labelSynlig, el.hasChildren, el.description))}
            onContextMenu={(event) => handleRightClick(event, new MenuItemTarget(el.rootName, el.parentName, el.name, el.code, el.tilpasset
              , el.tilpassetBeta, el.kodeSynlig, el.labelSynlig, el.hasChildren, el.description))}
            className={classes.content} style={{ height: 'auto ! important', }}
            key={el.code + count++}
            variant="body2"
            color="textSecondary"
            component="p">










            {el.kodeSynlig == 1 ? <span className={classes.subtitle}>{el.code}&nbsp;&nbsp;</span> : null}
            {el.labelSynlig == 1 ? <span >{' ' + el.description}</span> : null}





          </Typography>
        );
      }
    });



    //
    return <CardContent>{JSXItemElemments}</CardContent>;
  };
  //
  return (
    <Card elevation={3} className={classes.root}>
      <CardHeader className={classes.cardHeader} title={title} />
      {renderMenuItems()}
    </Card>
  );
}
