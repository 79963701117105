import { alpha, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { NV03Headers } from '../../../../models/NV03Valf';
import * as actionsNV03 from '../../../../store/actions/indexNV03';
import { AppState } from '../../../../store/rootReducer';

const useStyles = makeStyles()((/*theme: Theme*/) => ({
  tableCell: {
    marginLeft: -5, padding: 3, textAlign: 'center', fontSize: 21,
    backgroundColor: '#575655', color: '#fff', border: 'none',
    "& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
      color: "#9eacff"
    },
    // for active case hover color
    "& .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon": {
      color: "#9eacff"
    },
    "& .MuiTableSortLabel-root.Mui-active, .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
      fontWeight: 600,
      color: "#ffb89e"
    }
  },
}),
);


interface NV03TableHeadProps {
  onRequestSort: (property: string | NV03Headers[]) => void;
}

const NV03TableHead: React.FC<NV03TableHeadProps> = ({ onRequestSort }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const nV03Headers: NV03Headers[] = useSelector((state) => (state as AppState).NV03.nV03Headers);
  const isBeholdningChecked: boolean = useSelector((state) => (state as AppState).NV03.isBeholdningChecked);
  const isVeilPrisChecked: boolean = useSelector((state) => (state as AppState).NV03.isVeilPrisChecked);
  const isVisAlleLagerChecked: boolean = useSelector((state) => (state as AppState).NV03.isVisAlleLagerChecked);

  const rowsInitial: NV03Headers[] = [
    { id: "alfa", numeric: false, label: "Alfa", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "artikkelNummer", numeric: false, label: "Artikkel", width: '5%', order: 'asc', sortable: true, orderBy: true, filter: '' },
    { id: "vareSpes", numeric: false, label: "Varebetegnelse", width: '25%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "vareGruppe", numeric: false, label: "Varegruppe", width: '10%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "utSalgPris", numeric: false, label: "Pris", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "veilPris", numeric: false, label: "Veil Pris", width: '3%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "lokasjon", numeric: false, label: "Lokasjon", width: '15%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "behold", numeric: false, label: "Beholdning", width: '10%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "lk10", numeric: false, label: "LK10", width: '5%', order: 'desc', sortable: false, orderBy: false, filter: '' },
    { id: "lk15", numeric: false, label: "LK15", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "lk93", numeric: false, label: "LK93", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "auto", numeric: false, label: "Auto", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '' },
    { id: "Merknad", numeric: false, label: "Merknad", width: '5%', order: 'desc', sortable: true, orderBy: false, filter: '' },
  ];

  //
  useEffect(() => {
    if (nV03Headers.length === 0) {
      dispatch(actionsNV03.NV03UpdateColumnHeaderList(rowsInitial));
    }
  }, [dispatch, nV03Headers, isBeholdningChecked, isVeilPrisChecked, isVisAlleLagerChecked]);


  const handleClick = (property: NV03Headers) => {
    const filteredColumnHeaderList: NV03Headers[] = nV03Headers.map((row: NV03Headers) => {
      if (row.id === property.id) {
        return {
          ...row,
          orderBy: true,
          order: row.order === 'asc' ? 'desc' : 'asc',
        };
      }
      return {
        ...row,
        orderBy: false,
      };
    });

    dispatch(actionsNV03.NV03UpdateColumnHeaderList(filteredColumnHeaderList));

    if (onRequestSort) {
      onRequestSort(filteredColumnHeaderList);
    }
  };


  /*
  * Build the menu items parent nodes
  */
  const renderColumnHeader = () => {
    const JSXColumnHeader: JSX.Element[] = [];
    nV03Headers.forEach((row: NV03Headers) => {
      switch (true) {
        case !isBeholdningChecked && row.id === 'behold':
          break;
        case !isVeilPrisChecked && row.id === 'veilPris':
          break;
        case !isVisAlleLagerChecked && (row.id === 'lk10' || row.id === 'lk15' || row.id === 'lk93'):
          break
        default:
          if (row.id === 'lk10') {
            JSXColumnHeader.push(
              <TableCell
                className={classes.tableCell}
                key={row.id}
                align="left"
                padding="none"
              >
                <Tooltip
                  title={"Sort  " + row.orderBy}
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <div>{row.label}</div>
                </Tooltip>
              </TableCell>
            );
          } else
          JSXColumnHeader.push(
            <TableCell
              className={classes.tableCell}
              key={row.id}
              align="left"
              padding="none"
            >
              <Tooltip
                title={"Sort  " + row.orderBy}
                placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={row.orderBy}
                  direction={row.order}
                  onClick={() => handleClick(row)}
                >{row.label}</TableSortLabel>
              </Tooltip>
            </TableCell>
          );
          break;
      }


      //}
    });

    //
    return JSXColumnHeader;
  };

  //const JSXColumnHeader = property => event => { handleClick(event, property); };

  return (
    <TableHead>
      <TableRow>
        {renderColumnHeader()
        }
      </TableRow>
    </TableHead>
  );
};

export default NV03TableHead;