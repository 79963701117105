
import { makeStyles } from 'tss-react/mui';
import profil from '../assets/images/profil.svg';
import Utils from '../felles/Utils';

import poweroff from '../assets/images/poweroff.svg';


import { useDispatch, useSelector } from 'react-redux';
import C from '../felles/UtilsCSS';
import UserCred from '../models/UserCred';
import * as actions from '../store/actions/index';
import { history } from '../store/history';
import { AppState } from '../store/rootReducer';


const useStyles: any = makeStyles()(() =>
({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    marginTop: 6,
    //border: '1px solid',
    [C.IPAD_10]: { marginTop: 6, width: '100%', },
    [C.SAMSUNG]: { width: '100%', },
  },
  child: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
    marginLeft: 'auto',
    marginRight: '0',
    cursor: 'pointer',
    fontWeight: 500,
  },
  subchild: {
    paddingTop: '1px',
    paddingLeft: '4px'
  },

})
);

export default function LPNavigationProfile() {
  const { classes } = useStyles();
  const user: any = useSelector((state) => (state as AppState).auth.userJWT);


  const dispatch = useDispatch();
  const isAlreadyLoggedFromAnotherTab: boolean = useSelector((state) => (state as AppState).auth.isAlreadyLoggedFromAnotherTab);
  const tabListSize: number = useSelector((state) => (state as AppState).auth.tabListSize);
  //
  const handleLogoutClicked = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(actions.setIsLogged(4));
    dispatch(actions.setTabAsActive(true));
    dispatch(
      actions.UserLogoutFetchValidation(
        new UserCred('BAD', 'BAD', '', false),
        false,
        4,
        isAlreadyLoggedFromAnotherTab,
        tabListSize,
        true /*activeTab*/
      )
    );
  };


  //
  const handleProfileClickHandler = (event: any = null) => {
    event.preventDefault();
    history.push(Utils.HOMEPAGE_CONTEXT + '/BR04');
    return;
  }

  return (
    <div className={classes.root}>
      <div className={classes.child} onClick={handleProfileClickHandler} style={{ lineHeight: 1, }}>
        <img src={profil} alt="Profile" /><div className={classes.subchild}>{user?.sub}</div>
      </div>
      {/*<div className={classes.child} onClick={handleHelpClicked}>&nbsp;<img src={help} alt="Hjelp" />&nbsp;Hjelp</div>*/}
      <div className={classes.child} onClick={handleLogoutClicked}>&nbsp;<img src={poweroff} alt="Logout" />&nbsp;Logg&nbsp;ut</div>
    </div>
  );
}