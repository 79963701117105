import { Button, Tooltip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  vitecBt: {
    display: "inline-block",
    color: "#fff",
    borderRadius: 3, // Keep original border radius
    background: "#d30535",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Modern box shadow
    cursor: "pointer",
    verticalAlign: "middle",
    width: "100px",
    padding: "5px",
    marginTop: "13px",
    textAlign: "center",
    transition: "background 0.3s ease, transform 0.2s ease", // Smooth transitions
    "&:hover": {
      background: "#961932",
      color: "white",
      transform: "translateY(-2px)", // Slight lift on hover
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
      cursor: "pointer",
    },
    "&:active": {
      color: "white",
      background: "#cd5a6e",
      transform: "scale(0.98)", // Slight shrink on click
    },
    "&:disabled": {
      color: "white",
      background: "#e3e3e3",
      boxShadow: "none", // No shadow for disabled state
    },
  },
  highlighted: {
    backgroundColor: "#850f0f", // Updated color for modern look
    color: "#fff",
    border: "3px solid #fff", // Keep the white border as before
    boxShadow: "0 5px 12px rgba(255, 82, 82, 0.4)", // Softer shadow for highlight
    transform: "scale(1.02)", // Slight scale for emphasis
    transition: "all 0.3s ease", // Smooth transition for highlighted state
  },
}));

export default function ButtonVitec(props) {
  const { classes } = useStyles();



  return (
    <Tooltip title={props._tooltip || ""} placement={"top"} arrow>
      <Button
        type={props._type ? props._type : "button"}
        className={`${classes.vitecBt} ${props.highlighted ? classes.highlighted : ""}`} // Apply highlighted style conditionally
        onClick={props._onclick}
        disabled={props.disabled}
        style={{
          fontSize: props._fontsize,
          width: props._width,
          height: props._height,
          margin: props._padding,
        }}
      >
        {props._label}
      </Button>
    </Tooltip>
  );
}
