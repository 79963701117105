import { TradingPartner } from '../../models/NO01Orku';
import * as actionTypesNO01 from '../actions/actionTypesNO01';
import * as fromApp from "../rootReducer";

export interface FeatureState extends fromApp.AppState {
  state: NO01;
}

export interface NO01 {
  tradingPartners: TradingPartner[];
  error: any;
  isFetching: boolean;
}

const initialState: NO01 = {
  tradingPartners: [],
  error: undefined,
  isFetching: false,
};

const NO01Reducer = (state = initialState, action: any) => {
  //console.log("Reducer action: ", action.type);
  switch (action.type) {
    case actionTypesNO01.TRADING_PARTNERS_GET:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case actionTypesNO01.TRADING_PARTNERS_GET_SUCCESS:
      return {
        ...state,
        tradingPartners: action.payload,
        isFetching: false,
        error: undefined,
      };
    case actionTypesNO01.TRADING_PARTNERS_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default NO01Reducer;