import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import * as actions from '../store/actions/index';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import SVGHeaderLogo from '../components/SVGHeaderLogo';
import C from '../felles/UtilsCSS';
import UISize from '../models/UISize';
import { AppState } from '../store/rootReducer';


const useStyles = makeStyles()((t: Theme) => ({
  appBar: {
    backgroundColor: 'transparent', height: 40,
    transition: t.transitions.create(['margin', 'width'], {
      easing: t.transitions.easing.sharp,
      duration: t.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: 'transparent', width: `calc(100% - ${340}px)`, marginLeft: 340,
    transition: t.transitions.create(['margin', 'width'], {
      easing: t.transitions.easing.easeOut,
      duration: t.transitions.duration.enteringScreen,
    }),
  },
  rot: { display: 'flex', margin: 0 },

  logo: {
    opacity: 0.9, fontSize: '1em', zIndex: -500,
    [C.r(t, C.XXS, C.XXX)]: { width: '150px', marginTop: 17 },
    [C.r(t, C.XS, C.XSX)]: { width: '170px', marginTop: 17 },
    [C.r(t, C.SM, C.SMX)]: { width: '215px', marginTop: 17 },
    [C.r(t, C.MD, C.MDX)]: { width: '300px', marginTop: 17 },
    [C.r(t, C.LG, C.LGX)]: { width: '500px', marginTop: 25 },
    [C.r(t, C.XL, C.XLX)]: { width: '500px', marginTop: 25 },
    [C.r(t, C.XXL, C.YLX)]: { width: '500px', marginTop: 25 },
    [C.IPAD_10]: { width: '200px', marginTop: 22, marginLeft: 10, },
    [C.SAMSUNG]: { width: '170px', marginTop: 13, marginLeft: 10, },
  },
  onl: {
    paddingLeft: 15, marginTop: 20, color: '#2E2D2C', fontFamily: '"Inter", sans-serif', fontSize: '2.3em', fontWeight: 400, opacity: 0.75, zIndex: -500,
    [C.r(t, C.XXS, C.XXX)]: { fontSize: '1.3em', marginTop: 32 },
    [C.r(t, C.XS, C.XSX)]: { fontSize: '1.1em', marginTop: 20 },
    [C.r(t, C.SM, C.SMX)]: { fontSize: '1.3em', marginTop: 20 },
    [C.r(t, C.MD, C.MDX)]: { fontSize: '1.3em', marginTop: 38 },
    [C.r(t, C.LG, C.LGX)]: { fontSize: '1.8em', marginTop: 67 },
    [C.r(t, C.XL, C.XLX)]: { fontSize: '1.8em', marginTop: 67 },
    [C.r(t, C.XXL, C.YLX)]: { fontSize: '1.8em', marginTop: 67 },
    [C.IPAD_10]: { marginTop: 30, paddingLeft: 15, color: '#000', fontSize: '1.2em', },
    [C.SAMSUNG]: { marginTop: 17, paddingLeft: 10, color: '#000', fontSize: '1.2em', },
  },
  mB: {
    marginLeft: 3, marginTop: -55, color: '#2E2D2C', opacity: 1,
    [C.r(t, C.XXS, C.XXX)]: { marginTop: -10, color: '#fff' },
    [C.r(t, C.XS, C.XSX)]: { marginTop: -10, color: '#fff' },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 0, color: '#fff' },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 10, color: '#fff' },
    [C.IPAD_10]: { top: 0, color: '#000' },
    [C.SAMSUNG]: { top: 0, color: '#000' },
  },
  mBB: {
    marginLeft: 3, marginTop: -55, color: '#2E2D2C', opacity: 1,
    [C.r(t, C.XXS, C.XXX)]: { marginTop: -10, color: '#fff' },
    [C.r(t, C.XS, C.XSX)]: { marginTop: -10, color: '#fff' },
    [C.r(t, C.SM, C.SMX)]: { marginTop: 0, color: '#fff' },
    [C.r(t, C.MD, C.MDX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.LG, C.LGX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XL, C.XLX)]: { marginTop: 10, color: '#fff' },
    [C.r(t, C.XXL, C.YLX)]: { marginTop: 10, color: '#fff' },
    [C.IPAD_10]: { top: 0, color: '#000' },
    [C.SAMSUNG]: { top: 0, color: '#000' },
  },
  hide: { display: 'none' },
  //
  visible: {
    display: 'flex', margin: 0,
    visibility: 'visible',
    opacity: '1',
    transition: 'opacity .3s linear',
  },
  hidden: {
    display: 'flex', margin: 0,
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s .1s, opacity .1s linear',
  }
})
);

// todo: props need to be removed
export default function LPAppBarLogo(/*props*/) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector((state) => (state as AppState).ui.screenSize);
  const drawerOpenState: boolean = useSelector((state) => (state as AppState).ui.isDrawerOpened);
  const isButtonVisible: boolean = useSelector((state) => (state as AppState).ui.isSandwichButtonTurnedOn);
  // const user: any = useSelector((state) => (state as AppState).auth.userJWT);
  const scrollTop: number = useSelector((state) => (state as AppState).ui.scrollTop);
  //
  useEffect(() => {
    dispatch(actions.setDrawerState(!(screenSize.width > 0 && screenSize.width < 1200)));
  }, [dispatch, screenSize]);
  //
  const handleDrawerToggle = () => dispatch(actions.setSandwichButtonStateClicked(!isButtonVisible));
  const handleDebugMode = () => dispatch(actions.switchDebugMode());
  //
  return (
    <AppBar elevation={0} position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: isButtonVisible ? true : drawerOpenState })}>
      <div className={(scrollTop > 15 || scrollTop === 0) ? classes.visible : classes.hidden}>
        <IconButton color="inherit" aria-label="drawerOpenState drawer" onClick={handleDrawerToggle} edge="start"
          className={clsx({ [classes.mB]: isButtonVisible, [classes.mBB]: !isButtonVisible })}        >
          {!drawerOpenState ? <MenuIcon style={{ fontSize: 30, color: '#171b3d', zIndex: 3000 }} /> : []}
        </IconButton>
        <div className={classes.logo} onClick={handleDebugMode}>
          <SVGHeaderLogo width="100%" />
        </div>
        <div className={classes.onl}>Auto&nbsp;Online</div>

      </div>

    </AppBar>
  );
}