import jwtDecode from "jwt-decode";
export default class CSharpUtils {
  static get T_companyID() {
    const token = sessionStorage.getItem("token");
    return token
      ? (jwtDecode(token || "") as any).companyNr.toString()
      : undefined;
  }
  static get Get_Token() {
    const token = sessionStorage.getItem("token");
    return token
      
  }
  static get DOTNET_BASE_URL() {
    return process.env.REACT_APP_C_BASE_URL;
  }
}
export const validateKundenummer = (kundenummer: string): boolean => {
  const kundenummerPattern = /^\d{6}$/;
  return kundenummerPattern.test(kundenummer);
};

export const validateAlfa = (alfa: string): boolean => {
  // Minimum 3 alphabetic characters and can contain digits
  const hasMinThreeAlpha = /[a-zA-Z].*[a-zA-Z]/;
  return hasMinThreeAlpha.test(alfa);
};

export const validateArtikkle = (artikkle: string): boolean => {
  // Maximum 14 characters and can contain both digits and letters
  const artikklePattern = /^[a-zA-Z0-9]{1,14}$/;
  return artikklePattern.test(artikkle);
};

