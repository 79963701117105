import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import GridView from "./GridView";
import YearlySales from "./YearlySales";
import Header from "./components/Header";
import SearchFields from "./components/InputFields";
import { YearOptions } from "./components/YearOptions";
import { Box } from "@mui/material"; 
import ButtonVitec from "../../../components/ButtonVitec";
import { PaginationControls } from "./components/Pagination";
import { 
  fetchSalgDataRequest, 
  fetchSalgVareDataRequest, 
  fetchKundeSalgDataRequest, 
  fetchKundeDataRequest, 
  fetchTRANSAKSJONERRequest,
  clearData
} from "../../../store/actions/NV01.actions";
import CSharpUtils, { validateKundenummer, validateAlfa } from "../../../felles/CSharpUtils";
import { AppState } from '../../../store/rootReducer';
import { KundeData } from '../../../models/NV01SALG';
import VarerDetails from "./components/VarerDetail";
const Orku: React.FC = () => {
  const dispatch = useDispatch();
  const { Salg, SalgVarer, KundeData, KundeSalg, Transaction, error: reduxError } = useSelector((state: AppState) => state.NV01SALG);
  const searchedKundenummerRef = useRef<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [kundenummerInput, setKundenummerInput] = useState<string>("");

  const [isExportDisabled, setIsExportDisabled] = useState(true);

  const [searchedKundenummer, setSearchedKundenummer] = useState<string>("");
  const [alfa, setAlfa] = useState<string>("");
  const [artikkel, setArtikkel] = useState<string>("");
  const [varegruppe, setVaregruppe] = useState<string>("");
  const [salgDataType, setSalgDataType] = useState<string>("customerData");
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [sisteSalgData, setSisteSalgData] = useState("2024-08-11");
  const [selectedYears, setSelectedYears] = useState<{ [key: number]: boolean }>({
    2023: false,
    2024: true,
  });
  const [hasNextPage, setHasNextPage] = useState<boolean>(true); // To control if "Load More" button should appear

  const [suggestedCustomers, setSuggestedCustomers] = useState<KundeData[]>([]); // Should be an array
  const kundenummerRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if ((KundeSalg && KundeSalg.length > 0) || (Transaction && Transaction.length > 0)) {
      setIsExportDisabled(false);  // Enable the Export button if data exists
    } else {
      setIsExportDisabled(true);   // Disable the Export button if no data
    }
  }, [KundeSalg, Transaction]);
  // Clear Redux state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (kundenummerInput !== "") {
      fetchSuggestedCustomers();
    } else {
      setSuggestedCustomers([]);  // Reset to empty array instead of an empty string
    }
  }, [kundenummerInput]);

  const fetchSuggestedCustomers = useCallback(() => {
    setError(""); // Reset the error message
  
    if (!kundenummerInput) {
      setError("Please enter a valid customer number.");
      return;
    }
  
    const isAlphabetic = /^[A-Za-z]+$/.test(kundenummerInput); // Check if input contains only alphabetic characters
    const kundenavn = isAlphabetic ? kundenummerInput : ""; // If alphabetic, treat it as kundenavn
  
    if (isAlphabetic) {
      // Handle alphabetic input case
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, "", kundenavn));
    } else {
      // Handle numeric input case
      dispatch(fetchKundeDataRequest(CSharpUtils.T_companyID, kundenummerInput, kundenavn));
    }
  
    setSalgDataType("customerData"); // Set the type to "customerData"
  }, [kundenummerInput, dispatch]);

  const handleError = (err: unknown) => {
    if (err instanceof Error) {
      setError(err.message);
    } else {
      setError("An unknown error occurred.");
    }
  };


  const fetchTransactions = useCallback(
     (page = 1, pageSize = 500) => {
      if (isNaN(page) || page <= 0) {
        page = 1; // Set it to 1 if it's not a valid number
      }
 
   
      if (!validateAlfa(alfa)) {
        setError("Enter Alfa");
        return;
      }
    setShowYearOptions(false);
    console.log("toeknt of cshar",CSharpUtils.Get_Token)
       dispatch(fetchTRANSAKSJONERRequest(CSharpUtils.T_companyID, alfa, artikkel,  page, pageSize));
      dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
      setSalgDataType("transactionData");
      console.log("DATAr",Transaction)
    },
    [alfa, artikkel, dispatch]  // Ensure to include necessary dependencies
  );
  
  const handleLoadMoreTransactions = useCallback(() => {
    const nextPage = page + 1;
    fetchTransactions(nextPage, pageSize); // Fetch next page of transactions
    setPage(nextPage); // Increment the page
  
    // Set hasNextPage based on whether more data is available (this would depend on your API)
    // If no more data, setHasNextPage(false);
  }, [page, pageSize, fetchTransactions]);
  
  const handleKundeTopp50Click = useCallback(() => {
    dispatch(clearData());  // Clear existing data before fetching new data

    if (!validateKundenummer(searchedKundenummer)) {
      setError("Enter 6 siffer Kundenummer");
      return;
    }

    try {
      setShowYearOptions(true);

      const years = Object.keys(selectedYears)
        .filter((year) => selectedYears[parseInt(year, 10)])
        .map((year) => year.slice(-2))
        .join(",");

      dispatch(fetchKundeSalgDataRequest(searchedKundenummer, years, CSharpUtils.T_companyID));
      setSalgDataType("customerData");
    } catch (err) {
      handleError(err);
    }
  }, [searchedKundenummer, selectedYears, dispatch]);

  const handleHentSalgClick = useCallback(() => {
    dispatch(clearData());  // Clear existing data before fetching new data

    if (!validateAlfa(alfa)) {
      setError("Enter Alfa");
      return;
    }
    setShowYearOptions(false);
    dispatch(fetchSalgDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
    dispatch(fetchSalgVareDataRequest(CSharpUtils.T_companyID, alfa, artikkel));
    setSalgDataType("salgData");
  }, [alfa, artikkel, dispatch]);

  useEffect(() => {
    if (kundenummerInput && kundenummerInput.length === 6) {
      searchedKundenummerRef.current = kundenummerInput;  // Update ref, no re-render
      setSearchedKundenummer(searchedKundenummerRef.current);
    }
  }, [kundenummerInput]);
    
 // Function to export data to Excel
 const exportToCSV = () => {
  // Choose data to export based on the data type
  const dataToExport = salgDataType === "customerData" ? KundeSalg : Transaction;
  if (!dataToExport || dataToExport.length === 0) {
    setError("No data available to export.");
    return;
  }

  // Define different headers depending on the data type.
  const headers = salgDataType === "transactionData" 
    ? ["Dato", "Tid", "Tekst", "Ref.nr", "Kundenr", "Antall", "Ident","Sign", "Lager", "Lokasjon"] // Transaction data headers
    : ["Kundenummer", "Antall", "Alfa", "Artikkel", "Varebetegnelse", "Varegruppe"]; // Non-transaction data headers

  // Convert data to CSV format
  const csvContent = [
    headers.join(";"), // Join headers with a semicolon for the first row
    ...dataToExport.map((row) => {
      // Check if 'count' and 'sign' exist in the row, and apply the sign to count
      if (row.count !== undefined && row.sign !== undefined) {
        row.count = `${row.sign === '-' ? '-' : ''}${Math.abs(row.count)}`; // Apply sign only if it's negative
      }

      // Create an array of values, excluding 'sign'
      const rowData = Object.keys(row)
        .filter(key => key !== "sign") // Exclude the 'sign' key
        .map(key => row[key]); // Get the values for the remaining keys

      return rowData.join(";"); // Join each field in the row with a semicolon
    })
  ].join("\n"); // Add a new line after the headers and each row

  // Create a downloadable link for the CSV
  const fileName = salgDataType === "transactionData" 
    ? `Transaksjoner_${alfa}${artikkel}` // Transaction data file name
    : `Kundetopp100_${searchedKundenummer}`; // Non-transaction data file name
  
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    `data:text/plain;charset=utf-8,${encodeURIComponent(csvContent)}`
  );
  element.setAttribute("download", `${fileName}.csv`);
  
  // Append element to the DOM and trigger click
  document.body.appendChild(element);
  element.click();
  
  // Remove element from the DOM
  document.body.removeChild(element);
};



  return (
    <Container style={{ width: "1200px" }}>
      <Header
        onFetchTransactions={fetchTransactions}
        onFetchSalg={handleHentSalgClick}
        onFetchKundeTopp50={handleKundeTopp50Click}
        onhandleExport={exportToCSV}
        isExportDisabled={isExportDisabled}

      />
      <SearchFields
        alfa={alfa}
        artikkel={artikkel}
        varegruppe={varegruppe}
        kundenummerInput={kundenummerInput}
        setAlfa={setAlfa}
        setArtikkel={setArtikkel}
        setVaregruppe={setVaregruppe}
        setKundenummerInput={setKundenummerInput}
        suggestedCustomers={KundeData || []}  // Ensure KundeData is passed or default to an empty array
        handleCustomerSelect={(customer) => {
          
          setKundenummerInput(customer.id);
          setSearchedKundenummer(customer.id);
          setSuggestedCustomers([]);
        }}
        kundenummerRef={kundenummerRef}
      />
      {showYearOptions && (
        <YearOptions
          selectedYears={selectedYears}
          handleYearChange={(year) =>
            setSelectedYears((prev) => ({ ...prev, [year]: !prev[year] }))
          }
        />
      )}
      {(error || reduxError) && <Typography color="error">{error || reduxError}</Typography>}
     
      {salgDataType === "customerData" || salgDataType === "transactionData" ? (
     <>
     
     <VarerDetails varerdata={SalgVarer} sistesalg={sisteSalgData} />
     <GridView
       data={salgDataType === "customerData" ? KundeSalg : Transaction}
       kundenummer={searchedKundenummer}
       dataType={salgDataType}
     />
   </>
      ) : (
        <YearlySales
          data={Salg}
          varerdata={SalgVarer}
          sistesalg={sisteSalgData}
        />
      )}
      
     

        {/* Pagination Controls with margin for space */}
        <Box ml={2}>
        {(salgDataType === "transactionData" || salgDataType === "customerData") && (
            <PaginationControls
              hasNextPage={hasNextPage} // control "Load More"
              handleLoadMore={handleLoadMoreTransactions} // Handle load more functionality
             
            />
          )}
        </Box>
     
    </Container>
  );
};

export default Orku;
