import { takeLatest } from 'redux-saga/effects';
import * as actionTypesNV01 from '../actions/actionTypesNV01';
import { fetchSalgData } from './NV01.saga';


export function* watchfetchSALGSaga() {
  yield takeLatest(actionTypesNV01.SALG_SISTE_4_AAR_GET, fetchSalgData);
  yield takeLatest(actionTypesNV01.SALGVAREDATA_GET, fetchSalgData);
  yield takeLatest(actionTypesNV01.KUNDE_SALG_DATA_GET, fetchSalgData)
  yield takeLatest(actionTypesNV01.KUNDE_DATA_GET, fetchSalgData);
  yield takeLatest(actionTypesNV01.ALL_TRANSAKSJONER_GET, fetchSalgData);

} 

