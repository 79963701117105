import { put } from 'redux-saga/effects';
import { APIdeleteCriteriaFromList, APIgetCriterialExcelList, APIgetCriterialListItem, APIgetFavorittListe, APIpostDeleteFavorittListe, APIpostSalesReport, APIpostSaveFavorittListe, APIpostUpdateCriteriaItem, APIregisterCriteria, postUploadExcelDocumentWithSearchCriteria } from '../../felles/APICalls_SPRING';
import * as actions from '../actions';
import fileDownload from 'js-file-download';
import TaleSearchParams from '../../models/TaleSearchParams';
import TaleSalesReportItem from '../../models/payload/TTReportItem';

//
export function* criteriaItemListGetSaga() {
  yield put(actions.setIsloading(true));
  try {
    const response = yield APIgetCriterialListItem();
    if (response/* && response.data*/) {
      yield put(actions.criteriaItemListGetSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.userListFetchFailed(error));

  } finally {
    yield put(actions.setIsloading(false));
  }
}


//
export function* criteriaItemListGetAsExcelSaga() {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.criteriaItemListGetStart());
    const response = yield APIgetCriterialListItem();
    if (response/* && response.data*/) {
      yield put(actions.criteriaItemListGetSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.userListFetchFailed(error));
  } finally {
    yield put(actions.setIsloading(false));

  }
}


//
export function* criteriaItemUpdateSaga(payload: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.criteriaItemUpdateStarted());
    const response = yield APIpostUpdateCriteriaItem(payload.taleCriteriaItem);
    if (response) {
      yield put(actions.criteriaItemUpdateSuccess());
    }
  } catch (error) {
    yield put(actions.criteriaItemUpdateFailed(error));
  } finally {
    yield put(actions.setIsloading(false));

  }
}


export function* criteriaItemRegistrationSaga(payload: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.criteriaItemRegistrationStarted());
    const response = yield APIregisterCriteria(payload.taleCriterianItem);
    if (response) {
      yield put(actions.criteriaItemRegistrationSuccess(response.data));
    }
  } catch (error: any) {
    yield put(actions.criteriaItemRegistrationFailed(error.response.data));
  } finally {
    yield put(actions.setIsloading(false));

  }
}

//
export function* deleteCriteriaFromListSaga(payload: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.criteriaItemListSelectedDeletionStarted());
    const response = yield APIdeleteCriteriaFromList(payload.selectedCriteriaItemList);
    if (response) {
      yield put(actions.criteriaItemListSelectedDeletionSuccess());
    }
  } catch (error: any) {
    yield put(actions.criteriaItemListSelectedDeletionFailed(error.response.data));
  } finally {
    yield put(actions.setIsloading(false));

  }
}


// Excel
export function* getCriterialListToExcelDocumentSaga() {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.criteriaItemListExportToExcelInit());
    const response = yield APIgetCriterialExcelList();
    if (response) {
      yield fileDownload(response.data, 'TallenesTaleCrit-list__' + new Date().getTime() + '.xlsx');
      yield put(actions.criteriaItemListExportToExcelSuccess());
    }
  } catch (error) {
    yield put(actions.criteriaItemListExportToExcelFailed());
  } finally {
    yield put(actions.setIsloading(false));

  }
}

////////////////////////////////////////////////////////////////////////////
//
export function* favorittListeListGetSaga() {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.favorittListeGetStart());
    const response = yield APIgetFavorittListe();
    if (response) {
      yield put(actions.favorittListeGetSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.favorittListeGetFailed(error));
  } finally {
    yield put(actions.setIsloading(false));

  }
}

//
export function* favorittListeSaveSaga(payload: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.favorittListeUpdateStarted());
    const response = yield APIpostSaveFavorittListe(payload.taleFavorittListe);
    if (response) {
      yield put(actions.favorittListeUpdateSuccess());
      yield favorittListeListGetSaga();
    }
  } catch (error: any) {
    yield put(actions.favorittListeUpdateFailed(error.response.data));
  } finally {
    yield put(actions.setIsloading(false));

  }
}

export function* tallenesGetSalgsstatistikkSaga(payload: any) {
  //
  const t: TaleSearchParams = payload.taleSearchParams;
  //
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.tallenesGetSalgsstatiskkStart());

    const response = yield APIpostSalesReport(t);
    if (response && response.data && t.mediaType === 'web') {

      //Applying 25% MVA
      const finalTaleSalesReportItem: TaleSalesReportItem[] = response.data.map((obj: TaleSalesReportItem) => {
        obj.nettA = t.brutto ? obj.nettA * 1.25 : obj.nettA;
        obj.nettB = t.brutto ? obj.nettB * 1.25 : obj.nettB;
        obj.diff = t.brutto ? obj.diff * 1.25 : obj.diff;
        return obj
      });


      switch (true) {
        case t.visning === 'Kunde':
          yield put(actions.setTaleSearchKundeParams(t));
          yield put(actions.tallenesGetSalgsstatiskkKundeSuccess(finalTaleSalesReportItem));
          break;
        case t.visning === 'Varegruppe':
          yield put(actions.setTaleSearchVGruppeParams(t));
          yield put(actions.tallenesGetSalgsstatiskkVGruppeSuccess(finalTaleSalesReportItem));
          break;
        case t.visning === 'Hovedgruppe':
          yield put(actions.setTaleSearchHGruppeParams(t));
          yield put(actions.tallenesGetSalgsstatiskkHGruppeSuccess(finalTaleSalesReportItem));
          break;
        case t.visning === 'Gruppe':
          yield put(actions.setTaleSearchGruppeParams(t));
          yield put(actions.tallenesGetSalgsstatiskkGruppeSuccess(finalTaleSalesReportItem));
          break;
        case t.visning === 'Summeringsgruppe':
          yield put(actions.setTaleSearchSGruppeParams(t));
          yield put(actions.tallenesGetSalgsstatiskkSGruppeSuccess(finalTaleSalesReportItem));
          break;
      }

      yield put(actions.setfavorittListeVisningType(t.visning));


    } else if (response && t.mediaType === 'pdf') {
      yield fileDownload(response.data, t.butikkId + '-' + t.visning + '-' + t.datoA + '/' + t.datoB.substring(8, 10) + '_' + new Date().getTime() + '.pdf');
      yield put(actions.tallenesGetSalgsstatiskkPDFSuccess());
    } else if (response && t.mediaType === 'excel') {
      yield fileDownload(response.data, t.butikkId + '-' + t.visning + '-' + t.datoA + '/' + t.datoB.substring(8, 10) + '_' + new Date().getTime() + '.xlsx');
      yield put(actions.tallenesGetSalgsstatiskkExcelSuccess());
    } else if (response && t.mediaType === 'data') {
      yield fileDownload(response.data, t.butikkId + '-' + t.visning + '-' + t.datoA + '/' + t.datoB.substring(8, 10) + '_' + new Date().getTime() + '.xlsx');
      yield put(actions.tallenesGetSalgsstatiskkExcelSuccess());
    }
  } catch (error: any) {
    yield put(actions.tallenesGetSalgsstatiskkFailed(error?.response?.data));
  } finally {
    yield put(actions.setIsloading(false));

  }
}

//
export function* deleteFavorittListeSaga(payload: any) {
  yield put(actions.setIsloading(true));
  try {
    yield put(actions.favorittListeListSelectedDeletionStarted());
    const response = yield APIpostDeleteFavorittListe(payload.payload);
    if (response) {
      yield put(actions.favorittListeListSelectedDeletionSuccess());
      yield favorittListeListGetSaga();
    }
  } catch (error: any) {
    yield put(actions.favorittListeListSelectedDeletionFailed(error.response.data));
  } finally {
    yield put(actions.setIsloading(false));

  }
}

//
export function* postUploadExcelDocumentCriteria(action: any) {
  yield put(actions.setIsloading(true));
  yield put(actions.setExcelImportState(2));
  try {
    const response = yield postUploadExcelDocumentWithSearchCriteria(action.payload);
    if (response)
      yield put(actions.setExcelImportState(3));
  } catch (error: any) {
    yield put(actions.setExcelImportState(4));
  } finally {
    yield put(actions.setIsloading(false));
  }
}