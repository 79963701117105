import React, { useRef } from 'react';
import CustomInput from "./Input";
import { Paper, List, ListItem, ListItemText } from "@mui/material";

const SearchFields = ({
  alfa,
  artikkel,
  varegruppe,
  kundenummerInput,
  setAlfa,
  setArtikkel,
  setVaregruppe,
  setKundenummerInput,
  suggestedCustomers,
  handleCustomerSelect,
  kundenummerRef // Now expecting the ref prop
}) => {

  const artikkelRef = useRef<HTMLInputElement>(null);  // Ref for Artikkel input
  const varegruppeRef = useRef<HTMLInputElement>(null);  // Ref for Varegruppe input

  const handleChange = (setter, nextRef: React.RefObject<HTMLInputElement> | null = null, maxLength: number | null = null) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setter(value);
    
    // If maxLength is specified and reached, focus the next input field
    if (maxLength && value.length === maxLength && nextRef?.current) {
      nextRef.current.focus();
    }
  };
  const handleKundenummerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKundenummerInput(value);
  }
  const inputWidth = kundenummerRef.current
    ? kundenummerRef.current.offsetWidth
    : 'auto';

  return (
    <div style={{ display: "flex", marginBottom: "20px", position: "relative" }}>
      <CustomInput
        value={alfa}
        onChange={handleChange(setAlfa, artikkelRef, 3)}  // Move to Artikkel after 3 characters
        placeholder="Alfa"
      />
      <CustomInput
        value={artikkel}
        onChange={handleChange(setArtikkel, varegruppeRef, 14)}  // Move to Varegruppe after 14 characters
        placeholder="Artikkel"
        ref={artikkelRef}  // Attach the ref to the Artikkel input
      />
    <CustomInput
  value={varegruppe}
  onChange={handleChange(setVaregruppe, kundenummerRef, 5)}  // Move to Kundenummer after 5 characters
  placeholder="Varegruppe"
  ref={varegruppeRef}  // Attach the ref to the Varegruppe input
/>
      <div style={{ position: "relative", width: '100%' }}>
        <CustomInput
          value={kundenummerInput}
          onChange={handleKundenummerChange}
          placeholder="Kundenummer"
          ref={kundenummerRef} // Using the ref here
        />
      {suggestedCustomers && suggestedCustomers.length > 0 && suggestedCustomers[0].id && (
          <Paper
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              width: inputWidth,
              zIndex: 1,
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            <List>
              {suggestedCustomers.map((customer) => (
                <ListItem
                  button
                  key={customer.id}
                  onClick={() => handleCustomerSelect(customer)}
                >
                  <ListItemText primary={`${customer.id} - ${customer.name}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default SearchFields;
