//actionType til Salg fra siste 4 år PROG: VAST
export const SALG_SISTE_4_AAR_GET = 'SALG_SISTE_4_AAR_GET';
export const SALG_SISTE_4_AAR_SUCCESS = 'SALG_SISTE_4_AAR_SUCCESS';
export const SALG_SISTE_4_AAR_FAILURE = 'SALG_SISTE_4_AAR_FAILURE';

//ActionType til vare salg data alle salg på et varer
export const SALGVAREDATA_GET = 'SALGVAREDATA_GET';
export const SALGVAREDATA_SUCCESS = 'SALGVAREDATA_SUCCESS';
export const SALGVAREDATA_FAILURE = 'SALGVAREDATA_FAILURE';

//ActionType til kunde salg data, en kunde som har kjøt alle varer PROG:VARS
export const KUNDE_SALG_DATA_GET = 'KUNDE_SALG_DATA_GET';
export const KUNDE_SALG_DATA_SUCCESS = 'KUNDE_SALG_DATA_SUCCESS';
export const KUNDE_SALG_DATA_FAILURE = 'KUNDE_SALG_DATA_FAILURE';

//ActionType til kunde info navn og kundenummer
export const KUNDE_DATA_GET = 'KUNDE_DATA_GET';
export const KUNDE_DATA_SUCCESS = 'KUNDE_DATA_SUCCESS';
export const KUNDE_DATA_FAILURE = 'KUNDE_DATA_FAILURE';

//ActionType til Alle transasjoner på et varer 
export const ALL_TRANSAKSJONER_GET = 'ALL_TRANSAKSJONER_GET';
export const ALL_TRANSAKSJONER_SUCCESS = 'ALL_TRANSAKSJONER_SUCCESS';
export const ALL_TRANSAKSJONER_FAILURE = 'ALL_TRANSAKSJONER_FAILURE';


export const CLEAR_DATA = 'CLEAR_DATA';
