import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { YearlySalesData } from "../../../../models/NV01SALG";

interface YearlySalesTableProps {
  data: YearlySalesData[];
}

const YearlySalesTable: React.FC<YearlySalesTableProps> = ({ data }) => {
  const months: Record<string, string> = {
    jan: "Januar",
    feb: "Februar",
    march: "Mars",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    aug: "August",
    sept: "September",
    okt: "Oktober",
    nov: "November",
    des: "Desember",
  };

  const formatAmount = (amount: number): string => {
    const formattedAmount = amount.toLocaleString("no-NO", {});
    return formattedAmount.replace(/\s/g, ".");
  };

  const totals = data.map((yearData) => {
    const totalAntall = Object.values(yearData.months).reduce(
      (acc, month) => acc + (month?.count ?? 0),
      0
    );
    const totalBelop = Object.values(yearData.months).reduce(
      (acc, month) => acc + (month?.total_amount ?? 0),
      0
    );

    return { totalAntall, totalBelop };
  });

  return (
    <TableContainer component={Paper} sx={{ marginTop: 5 }}>
      <Table>
        <TableHead >
          <TableRow>
            <TableCell />
            {data.map((d, index) => (
              <TableCell key={index} colSpan={2} align="center" sx={{  color: "black" }}>
                {d.year}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{  color: "black" }}>Måned</TableCell>
            {data.map((d, index) => (
              <React.Fragment key={index}>
                <TableCell sx={{  color: "black" }} align="right">
                  Antall
                </TableCell>
                <TableCell sx={{ color: "black" }} align="right">
                  Beløp
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(months).map((m, index) => (
            <TableRow key={index}>
              <TableCell>{months[m]}</TableCell>
              {data.map((d, index) => (
                <React.Fragment key={index}>
                  <TableCell align="right">{d.months[m]?.count ?? ""}</TableCell>
                  <TableCell align="right">
                    {d.months[m]?.total_amount !== undefined ? formatAmount(d.months[m].total_amount) : ""}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          ))}
          <TableRow sx={{ backgroundColor: "#dddddd" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
            {totals.map((total, index) => (
              <React.Fragment key={index}>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {total.totalAntall}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {formatAmount(total.totalBelop)}
                </TableCell>
              </React.Fragment>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default YearlySalesTable;
