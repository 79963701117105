import { CssBaseline, Theme } from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SizeMe } from "react-sizeme";
import { makeStyles } from "tss-react/mui";

import LPAppBar from "../../../components/LPAppBar";
import LPDrawer from "../../../components/LPDrawer";
import ShiftHome from "../../../components/ShiftHome";
import MenuItemTarget from "../../../models/MenuItemTarget";
import UISize from "../../../models/UISize";
import * as actions from "../../../store/actions/index";
import { AppState } from "../../../store/rootReducer";
import Orku from "./NV01VARS";

const useStyles = makeStyles()((theme: Theme) => ({
  root: { display: "flex", height: "100vh", width: "100vw" },
  content: {
    marginTop: 45,
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -340,
  },
  contentShift: {
    marginTop: 45,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function NV01Page(/*props*/) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const screenSize: UISize = useSelector(
    (state) => (state as AppState).ui.screenSize
  );
  //const menuItems: MenuEl[] = useSelector(state => (state as AppState).menuItem.menuItems);
  const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;
  const navigate = useNavigate();
  const n: MenuItemTarget = useSelector(
    (state) => (state as AppState).menuItem.menuItemTarget
  );
  const criteriaItemEditState = useSelector(
    (state) => (state as AppState).tallenesTale.criteriaItemEditState
  );
  const criteriaItemDeletionState = useSelector(
    (state) => (state as AppState).tallenesTale.criteriaItemDeletionState
  );
  const criteriaItemRegistrationState = useSelector(
    (state) => (state as AppState).tallenesTale.criteriaItemRegistrationState
  );
  const criteriaItemId = useSelector(
    (state) => (state as AppState).tallenesTale.criteriaItemId
  );
  const excelImportState = useSelector(
    (state) => (state as AppState).tallenesTale.excelImportState
  );

  //

  const button: boolean = useSelector(
    (state) => (state as AppState).ui.isSandwichButtonTurnedOn
  );

  return (
    <div className={classes.root} tabIndex={0}>
      <ShiftHome />
      <CssBaseline />
      <LPDrawer />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: button ? true : open,
        })}
      >
        <LPAppBar ismenuvisible={true} />
        <Orku />
      </main>
    </div>
  );
}
