import { CssBaseline, Theme } from "@mui/material";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";

import LPAppBar from "../../../components/LPAppBar";
import LPDrawer from "../../../components/LPDrawer";
import ShiftHome from "../../../components/ShiftHome";
import UISize from "../../../models/UISize";
import { AppState } from "../../../store/rootReducer";
import Orku from "./Orku";

const useStyles = makeStyles()((theme: Theme) => ({
  root: { display: "flex", height: "100vh", width: "100vw" },
  content: {
    marginTop: 45,
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -340,
  },
  contentShift: {
    marginTop: 45,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function NO01Page(/*props*/) {
  const { classes } = useStyles();
  const screenSize: UISize = useSelector(
    (state) => (state as AppState).ui.screenSize
  );

  const open = screenSize.width > 0 && screenSize.width < 1200 ? false : true;

  const button: boolean = useSelector(
    (state) => (state as AppState).ui.isSandwichButtonTurnedOn
  );

  return (
    <div className={classes.root} tabIndex={0}>
      <ShiftHome />
      <CssBaseline />
      <LPDrawer />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: button ? true : open,
        })}
      >
        <LPAppBar ismenuvisible={true} />
        <Orku/>
      </main>
    </div>
  );
}
