
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Theme, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from '../../../../components/ButtonVitec';
import Utils from '../../../../felles/Utils';
import * as actions from '../../../../store/actions/index';
import { AppState } from '../../../../store/rootReducer';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        paddingRight: theme.spacing(1), backgroundColor: '#2E2D2C', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'
    },

    spacer: { flex: "1 1 100%" },
    actions: { color: theme.palette.text.secondary, marginTop: -10 },
    buttonA: { width: 250, marginRight: theme.spacing(1) },
    title: { flex: "0 0 auto", backgroundColor: '#2E2D2C' }
}));

const CriteriaListTableToolbar = (/*props*/) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const selectedCriteriaItemList: number[] = useSelector(state => (state as AppState).tallenesTale.selectedCriteriaItemList);
    const setTaleDeletionState = () => dispatch(actions.setCriteriaDeletionState(1));
    const user: any = useSelector((state) => (state as AppState).auth.userJWT);

    useEffect(() => {
        // theme.palette.primary.main;
        const loc = <>
            Salgsstatistikker {'>'} Redigering av sorteringskriteriene for {user?.company?.toString()} </>
        dispatch(actions.setLocationPath(Utils.setLocationPath(loc)));
    }, [dispatch]);


    // Redigering av sorteringskriteriene
    const sendRequest = () => dispatch(actions.setCriteriaRegistrationState(1));
    //
    return (
        <Toolbar sx={{ color: 'red', backgroundColor: '#2E2D2C', border: 'none' }}
            className={classes.root}>
            <div className={classes.title} >
                {selectedCriteriaItemList?.length > 0 ? (
                    <Typography variant="subtitle1" sx={{ color: 'white', backgroundColor: '#2E2D2C' }}>
                        {selectedCriteriaItemList?.length} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle"></Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selectedCriteriaItemList?.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => setTaleDeletionState()}>
                            <DeleteIcon sx={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                ) :
                    <ButtonVitec _onclick={() => sendRequest()} _width={280} _height={37} _type={'submit'} _label={'Oppretting av varegruppe'} /> //sx={{color:'red'}}
                    //<ButtonVitec className={classes.buttonA} variant="contained" onClick={() => sendRequest()}>Oppretting av varegruppe</ButtonVitec>
                }
            </div>
        </Toolbar>
    );
};

export default CriteriaListTableToolbar;
