
import { Backdrop, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import qs from 'qs';
import { createRef, useEffect } from 'react';
import ReactDomServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ButtonVitec from "../../components/ButtonVitec";
import Utils from '../../felles/Utils';
import BugFormData from '../../models/BugFormData';
import UserUpdatePayload from '../../models/UserUpdatePayload';
import * as actions from '../../store/actions/index';
import { history } from '../../store/history';
import { AppState } from '../../store/rootReducer';


const useStyles: any = makeStyles()((theme: Theme) =>
({
  root: {
    width: '100vw', marginLeft: 40, marginRight: 40, paddingTop: 40, zIndex: theme.zIndex.drawer + 2, position: 'fixed', top: 0
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: '#fff' },


  title: { fontSize: '2em', margin: '10px', },
  from: { fontSize: '1.2em', margin: '10px', },
  myButton: { width: 300, padding: '12px', border: '1px solid #ccc', },
  myForm: { padding: '12px' },

  formAdd: {
    marginTop: 'min(20%)',
    marginBottom: 'min(20%)',
    boxShadow: '3px 3px 3px #222',
    horizontalAlign: 'center',
    backgroundColor: '#f3f3f3',
    width: '50vw',
    margin: '0 auto',
    padding: 30,
    paddingTop: 15,
    paddingBottom: 5,
    border: '1px solid #333',
    borderRadius: '5px', color: '#000',
    fontSize: '1em',
  },
  myInputWrapper: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  myInput: { width: '100%', padding: '10px', marginBottom: '14px', marginTop: '3px', border: '1px solid #ccc' },
  myInputShort: { width: '70px', padding: '10px', marginBottom: '14px', marginTop: '3px', marginLeft: '10px', border: '1px solid #ccc' },
  myInputVeryShort: {
    userSelect: 'none',
    width: '40px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    marginLeft: '10px',
    border: '1px solid #ccc',
  },
  myInputVeryShortC: {
    userSelect: 'none',
    width: '200px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    marginLeft: '10px',
    border: '1px solid #ccc',
  },
  myInputVeryShortB: {
    userSelect: 'none',
    webkitUserSelect: 'none',
    width: '50px',
    padding: '10px',
    marginBottom: '10px',
    marginTop: '3px',
    border: '1px solid #ccc',
  },
  preview: {
    alignItems: 'center',
    //border: '1px solid #cbd5e0',
    display: 'flex',
    maxWidth: '150px',
    maxHeight: '150px'

  },
  myLabel: { width: 600, fontSize: '1.2em' },
  myTitle: { width: 600, fontSize: '1.6em', marginBottom: '15px', color: 'black' },
  myInputTitle: { width: 600, fontSize: '1.3em', color: 'grey' },
  formError: { fontSize: '1em', marginTop: '30px', color: 'red', align: 'center' },
}));

const BugRepportHandler = (/*props*/) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const userData: UserUpdatePayload = useSelector(state => (state as AppState).profile.userProfileData);
  // const error: any = useSelector(state => (state as AppState).error.error);
  // const errorInfo: string = useSelector(state => (state as AppState).error.errorInfo);
  // const transError: SimpleMSG = useSelector(state => (state as AppState).trans.error);
  const formRef: any = createRef();
  const recordingState: number = useSelector((state) => (state as AppState).utils.recordingState);
  //const fileInput = document.getElementById("document_attachment_doc");
  const reportingLog: any = useSelector((state) => (state as AppState).utils.recordingLog);
  const transId: string = qs.parse(history.location.search, { ignoreQueryPrefix: true }).transid as string;

  useEffect(() => {
    if (userData.username === '')
      dispatch(actions.userProfileDataFetch());

    window.addEventListener('paste', function (evt: any) {
      const clipboardItems = evt?.clipboardData?.items;
      const items: any = [].slice.call(clipboardItems).filter(function (item) {
        return /^image\//.test((item as any).type);
      });
      if (items.length === 0) {
        return;
      }

      const item = items[0];
      const blob = item.getAsFile();

      const imageEle: any = document.getElementById('preview');
      imageEle.src = URL.createObjectURL(blob);
      const file = new File([blob], "filename.png", { type: "image/png", lastModified: new Date().getTime() }/*, 'utf-8'*/);
      const container = new DataTransfer();
      container.items.add(file);
      if (document.querySelector('#file_input') !== null) {
        (document.querySelector('#file_input') as any).files = container.files || null;
      }
    });

  }, [dispatch]);

  const handleOKClickedBtn = () => {
    dispatch(actions.setRecordingState(0));
    dispatch(actions.updateRecordingLog([]));
  }

  const onCancel = () => {
    dispatch(actions.setRecordingState(1));
  }
  /*
    const onSubmit = (e) => {
      e.preventDefault();
      dispatch(actions.setRecordingState(3));
      const formData = new FormData();
      const data = Array.prototype.filter.call(e.target.elements,
        (input) => {
          formData.append(input.id, input.value)
        });
      Array.prototype.map.call(data, (input) => {
        input.id !== 'file' ? formData.append(input.id, input.value) : formData.append('file', input.files[0]);
      });
      dispatch(actions.BugReportingPost(formData));
    }
  */


  const onSubmit = (e) => {
    e.preventDefault();
    const body = new FormData();
    let fileName = '';
    const data = Array.prototype.filter.call(e.target.elements, (input) => {
      switch (input.type) {
        case 'file':
          fileName = input.value.split('fakepath\\')[1];
          //if (fileName.includes('.')) fileName = fileName.split('.')[0] + '.jpg';
          break;
        default:
          break;
      }

      if (input.nodeName === 'BUTTON') return false;
      return true;
    });
    Array.prototype.map.call(data, (input) => {
      input.id !== 'file_input' ? body.append(input.id, input.value) : body.append('file', input.files[0]);
    });

    console.log(fileName);

    dispatch(actions.BugReportingPost(new BugFormData(fileName, '', '', '', body)));
  };


  //
  const generateRecordingStates = () => {
    switch (true) {
      case recordingState === 2:
        return (
          <>
            <Backdrop className={classes.backdrop} open={true} />
            <div className={classes.root}>
              <div style={{ width: '40vw', margin: 'auto', textAlign: 'left' }}>
                <form ref={formRef} onSubmit={onSubmit} encType="multipart/form-data" className={classes.formAdd}>
                  <input className={classes.myInput} type="hidden" id="from" name="from" value={userData.username + ', ' + userData.email + ', ' + userData.mobile} />
                  <div style={{ fontSize: '1em', display: 'flex' }}>Location:  {Utils.HOST_NAME}, Version:  {Utils.T_apiVersion}, {Utils.T_parentId}</div>
                  <input className={classes.myInput} type="hidden" id="location" value={Utils.HOST_NAME + ', ' + Utils.T_apiVersion + ', ' + Utils.T_parentId} /><br />
                  <input className={classes.myInput} type="hidden" id="subject" placeholder="Subject..." defaultValue={'PROG: ' + transId + ' fra ' + userData.username + ' ' + userData.email + ' ' + userData.mobile} />
                  <div style={{ fontSize: '1em', display: 'flex' }}>Send rapport som:</div>
                  <select className={classes.myInput} name="reporttype" id="reporttype">
                    <option value="Bug Report fra: ">Bug report</option>
                    <option value="Test Case fra: ">Test case</option>
                    <option value="Feature ønsket: ">Feature ønsket</option>
                  </select>
                  <div style={{ fontSize: '1em', display: 'flex' }}>Ekstra informasjon:</div>
                  <textarea className={classes.myInput} id="description" rows={10} placeholder={'Hvis ønskelig, legg til ekstra informasjon her: '} >
                  </textarea>
                  <input className={classes.myInput} id="log" type="hidden" value={ReactDomServer.renderToString(reportingLog)} />
                  <input className={classes.myInput} id="file_input" type="file" accept=".*" /><br />
                  <div style={{ fontSize: '.8em', display: 'flex' }}>{'eller, SHFT + WINDOWS + S <=> Eller CTRL + V'}</div>
                  <img className={classes.preview} id="preview" />
                  <div style={{ width: '100%', textAlign: 'center' }}><ButtonVitec _type={'submit'} _onclick={() => null} _fontsize={'1em'} _name={'Send'} _label={'Send'} _width={150} _height={35} _padding={5} _margin={0} />
                    <ButtonVitec _type={'button'} _fontsize={'1em'} _onclick={onCancel} _name={'cancel'} _label={'Avbryt (continue)'} _width={180} _height={35} _padding={5} _margin={0} /></div>
                  < br />
                </form>
              </div>
            </div>
          </>
        );
      case recordingState === 3:
        return <Backdrop className={classes.backdrop} open={true} >
          <CircularProgress color="inherit" /></Backdrop>;
      case recordingState === 4:
        return (
          <Backdrop className={classes.backdrop} open={true} >
            <div className={classes.root} style={{ position: 'fixed', }}>
              <div className={classes.formAdd} style={{ textAlign: 'center' }}>
                <br />
                Informasjonen er vellykket sendt til vår utvikler.<br />Vi takker deg for bidraget ditt.<br />
                <ButtonVitec _type={'button'} _fontsize={'1em'} _onclick={handleOKClickedBtn} _name={'OK'} _label={'Ok'} _width={150} _height={30} _padding={5} _margin={0} />< br /><br />
              </div></div></Backdrop>
        );
      case recordingState === 5:
        return (
          <Backdrop className={classes.backdrop} open={true} >
            <div className={classes.root} style={{ position: 'fixed', }}>
              <div className={classes.formAdd} style={{ textAlign: 'center' }}>
                <br />
                Informasjonen er vellykket sendt til vår utvikler.<br />Vi takker deg for bidraget ditt.<br />
                <ButtonVitec _type={'button'} _fontsize={'1em'} _onclick={handleOKClickedBtn} _name={'OK'} _label={'Ok'} _width={150} _height={30} _padding={5} _margin={0} />< br /><br />
              </div>
            </div>
          </Backdrop>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {generateRecordingStates()}
    </>
  )
}

export default BugRepportHandler;